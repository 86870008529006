.navbar {
    min-height: 50px !important;
}

.navbar.socialite {
    height: 60px;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #FF6C00 !important;
}

.navbar-brand.socialite {
    padding-top: 6px;
    height: 50px;
}

.logo {
    width: 151px;
    height: 44px;
    display: block;
    text-transform: capitalize;
    font-family: @primary-font;
    color: #fff;
    padding-top: 12px;
    font-size: 20px;
    font-weight: 400;
    margin-top: 1px;
    img {
        margin-top: -2px;
        margin-left: 15px;
        margin-right: 10px;
    }
}

.no-bg {
    background: #FF6C00 url(../img/header-bg.png) repeat-x bottom center;
}
.notif-button{
    position: absolute;
    width: 35px;
    height: 35px;
    top:-3px;
    background-color: rgba(0,0,0,.1);
    border-radius: 4px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigation-drop{
    position: relative;
    width: 100%;
    height: 35px;
    background-color: rgba(0,0,0,.1);
    border-radius: 4px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    top:-3px;
}
.img-26{
    width: 26px;
    height: 26px;
}
.form-left {
    margin-top: 10px;
    margin-bottom: 0px;
    .form-control {
        margin-left: 0px !important;
        border-left-width: 0px;
        border-right-width: 1px !important;
        height: 30px !important;
        width: 244px !important;
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        .selectize-input {
            height: 39px;
            min-height: 30px;
            max-width: 335px;
            border-color: transparent;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-left-width: 1px;
            padding-top: 9px;
            padding-left: 8px;
            border-color: transparent !important;
            background: rgba(0,0,0,.1) !important;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            input[type="text"]{
                color: #fff;


            }
        }
        .selectize-input input[type="text"]::placeholder{
            color: #fff !important;
        }
        .selectize-input input[type="text"]::moz-placeholder {
            color: #fff !important;
            opacity: 1; /* Firefox */
        }

        .selectize-input.focus {
            box-shadow: none;
        }
        .selectize-dropdown {
            height: auto !important;
            // border-width: 0px !important;
            background-color: #fff;
            border-radius: 0px;
            border: 1px solid @panel-border-color !important;
            border-top-width: 0px;
            top: -7px !important;
            .selectize-dropdown-content {
                padding-top: 0px;
                padding-bottom: 0px;
                @media (max-width: 500px) {
                    max-width: 100%;
                }
                .optgroup {
                    .optgroup-header {
                        font-family: @primary-font;
                        font-weight: 400;
                        font-size: @primary-font-size;
                        color: @primary-content-color;
                        letter-spacing: 0px;
                        padding-left: 15px;
                        padding-top: 10px;
                        padding-bottom: 6px;
                        background-color: #fff;
                    }
                    .big-search-dropdown {
                        padding-left: 15px;
                        padding-top: 4px;
                        .media-left {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 4px;
                            }
                            padding-right: 15px;
                        }
                        .media-body {
                            .media-heading {
                                font-family: @primary-font;
                                font-size: @primary-font-size;
                                color: @primary-content-color;
                                letter-spacing: 0px;
                                margin-bottom: 0px;
                                line-height: 19px;
                            }
                            p {
                                font-family: @primary-font;
                                font-size: 12px;
                                color: #9A9FA8;
                                letter-spacing: 0px;
                                max-height: 43px;
                                overflow: hidden;
                                margin-bottom: 0px;
                                height: 18px;
                                overflow: hidden;
                                width: 250px;
                                line-height: 15px;
                            }
                        }
                    }
                    .big-search-dropdown.active {
                        background-color: #fafbfc;
                        // background-color: #eff2f5;
                    }
                }
            }
        }
    }
    .input-group-btn {
        .btn {
            border-left-width: 1px !important;
            border-right-width: 0px;
            background: #fff !important;
            height: 30px !important;
            padding-top: 4px;
            padding-right: 6px;
        }
    }
}

.input-group .header-search-btn .btn {
    padding: 4px 5px;
    height: 39px !important;
    width: 47px;
    background-color: rgba(0,0,0,.1) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: transparent !important;
}

.input-group .header-search-btn .btn .fa-search {
    color: #fff;
}

.notification-list {
    border-right: none;
    > li {
        padding-left: 12px;
        padding-right: 12px;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 26px;
        }
        > a {
            padding-top: 5px;
            padding-bottom: 0px;
            display: block;
            text-decoration: none;
            @media (max-width: 1010px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            i {
                font-size: 22px;
                color: #fff;
                letter-spacing: 0.46px;
                position: relative;
            }
            .count {
                min-width: 19px;
                height: 16px;
                padding-left: 5px;
                padding-right: 6px;
                background: #fff;
                font-family: @primary-font;
                font-weight: 400;
                border-radius: 3px;
                position: absolute;
                text-align: center;
                top: -15px;
                line-height: 16px;
                right: -15px;
                font-size: 12px;
                color: #ff6c00;
                letter-spacing: 0.17px;
            }
        }
        .small-screen {
            display: none;
            margin-left: 10px;
            text-transform: capitalize;
            color: @primary-content-color;
            font-size: 16px;
            font-family: @primary-font;
            @media (max-width: 1010px) {
                display: inline-block;
            }
        }
    }
    .chat-list-toggle {
        @media (max-width: 1325px) {
            display: none;
        }
    }
}

.dropdown.message {
    @media (max-width: 1325px) {
        padding-right: 26px;
    }
    .dropdown-menu {
        width: 500px;
        border-radius: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        border-top: 4px solid #ff6c00;
        margin-top:0px;
        @media (max-width: 1010px) {
            left: 34px;
            position: static;
            border: 1px solid @panel-border-color;
            width: 100%;
            border-top: 2px solid #ff6c00;
            margin-bottom: 10px;
        }
        .dropdown-menu-header {
            font-family: @primary-font;
            font-size: @primary-font-size;
            padding-left: 20px;
            padding-right: 20px;
            border-bottom: 1px solid @panel-border-color;
            .side-left {
                color: #354053;
                text-transform: capitalize;
                padding-top: 12px;
                padding-bottom: 12px;
                float: left;
                font-size: 18px;
                font-weight: 600;
            }
            .side-right {
                padding-top: 7px;
                padding-bottom: 9px;
                float: right;
                li {
                    &:last-child {
                        padding-right: 0px;
                    }
                    padding-right: 6px;
                    border-bottom-width: 0px;
                }
                a {
                    color: #289DF5;
                    text-transform: capitalize;
                }
            }
        }
        .dropdown-menu-footer {
            border-top: 1px solid @panel-border-color;
            text-align: center;
            padding-top: 14px;
            padding-bottom: 14px;
            background-color: #ff6c00;
            a {
                text-transform: capitalize;
                color: #fff;
                font-size: 18px;
            }
        }
        .no-messages {
            text-align: center;
            border-bottom: 1px solid @panel-border-color;
            i {
                padding-top: 40px;
                font-size: 100px;
                color: @secondary-content-color;
                letter-spacing: 0px;
            }
            p {
                font-family: LucidaGrande;
                font-size: 18px;
                color: @secondary-content-color;
                letter-spacing: 0px;
                text-align: center;
                padding-top: 50px;
                padding-bottom: 5px;
            }
        }
        .dropdown-messages-list {
            height: 250px;
            overflow-x: hidden;
            overflow-y: scroll;
        }
        > ul li {
            border-bottom: 1px solid @panel-border-color;
            padding-left: 20px;
            padding-right: 20px;
            &:last-child {
                border-bottom: 0px;
            }
            .media {
                .media-left {
                    img {
                        width: 56px;
                        height: 56px;
                        border-radius: 2px;
                    }
                }
                .media-body {
                    padding-left: 5px;
                    .media-heading {
                        margin-bottom: 0px;
                        .message-heading {
                            font-family: @primary-font;
                            font-size: 20px;
                            font-weight: 600;
                            color: #354053;
                            letter-spacing: 0px;
                            text-transform: capitalize;
                            text-decoration: none;
                            display: inline-block;
                            text-overflow: ellipsis;
                            max-width: 175px;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .online-status {
                            background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
                            border: 1px solid #36AF47;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            display: inline-block;
                            margin-left: 7px;
                        }
                        .message-time {
                            float: right;
                            font-family: @primary-font;
                            margin-top: 1px;
                            font-weight: 600;
                            font-size: 16px;
                            color: #354053;
                            letter-spacing: 0px;
                        }
                    }
                    .message-text {
                        font-family: @primary-font;
                        font-weight: 400;
                        font-size: 16px;
                        color: #354053;
                        letter-spacing: 0px;
                        line-height: 19px;
                        margin-bottom: 0px;
                        height: 19px;
                        overflow: hidden;
                        width: 245px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        li.inbox-message {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: 0;
            background-color: #fff;
            > a {
                display: block;
                width: 100%;
                text-decoration: none;
                .media-heading {
                    color: @primary-content-color;
                    font-weight: 600;
                }
            }
            &:hover {
                background-color: #FAFBFC;
            }
        }
        li.inbox-message.active {
            background-color: #FAFBFC;
        }
    }
}

.dropdown.message.notification {
    @media (max-width: 1325px) {
        padding-right: 12px;
    }
    .dropdown-menu {
        > ul li {
            .media-left {
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            .media-heading {
                .notification-text {
                    font-family: @primary-font;
                    font-weight: 600;
                    font-size: @primary-font-size;
                    display: block;
                    line-height: 13px;
                }
                .message-time {
                    display: block;
                    margin-top: 0px;
                    float: left !important;
                    letter-spacing: 0px;
                    .notification-type {
                        display: inline-block;
                        background: #289DF5;
                        border: 1px solid #289DF5;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        border-radius: 50%;
                        line-height: 14px;
                        margin-right: 7px;
                        i {
                            color: #fff;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}
.admin-drop{
    background-color: rgba(0,0,0,.1) !important;
    border-radius: 4px !important;
    padding: 5px !important;
    display: flex !important;
    align-items: center !important;
    &:hover{
        background-color: rgba(0,0,0,.1) !important;
    }
}
.user-image.dropdown.socialite {
    padding-bottom: 16px;
    padding-top: 9px;

    >a {
        margin-left: 26px;
        @media (max-width: 1010px) {
            margin-left: 8px;
        }
        .user-name {
            margin-left: 7px;
            font-family: @primary-font;
            font-size: 16px;
            color: #fff;
            letter-spacing: 0.26px;
            text-transform: capitalize;
        }
        i {
            color: #fff;
            font-size: 16px;
            border-radius: 83px;
            margin-left: 10px;
        }
        i.fa-angle-down {
            margin-right: 0px;
        }
    }
    .dropdown-menu {
        background-color: #fff;
        width: 89%;
        border-top: 2px solid #ff6c00;
        padding-top: 0px;
        padding-bottom: 0px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 19px;
        li {
            border-bottom: 1px solid @panel-border-color;
            margin-left: 0px;
            padding-left: 0px !important;
            &:last-child {
                border-bottom-width: 0px;
            }
            &:hover,
            &.active {
                a {
                    background-color: #ff6c00 !important;
                    color: #FFFFFF;
                }
                i {
                    color: #fff;
                }
            }
            a {
                margin-left: 0px;
                padding: 10px 10px 10px 2px;
                font-family: @primary-font;
                font-weight: 400;
                font-size: @primary-font-size;
                color: @black;
                letter-spacing: 0px;
                text-transform: capitalize;
                @media (max-width: 1010px) {
                    padding-left: 20px;
                }
            }
            i {
                font-size: 18px;
                color: @black;
                vertical-align: middle;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 1010px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
        margin-top:14px;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
        margin-top:1px;
        background-color: #191919;
        border-bottom: 1px solid @panel-border-color;
        margin-left: -15px !important;
        margin-right: -15px !important;
        border-radius: 0px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-form {
        border-top-width: 0px;
        border-bottom-width: 0px;
        float: none !important;
        .input-group {
            width: 100%;
            .input-group-btn {
                width: 31px;
            }
            .selectize-input {
                max-width: none;
            }
        }
        button.btn {
            margin-left: 0px;
        }
        @media (max-width:768px) {
            margin-left: 0px;
            width: 100%;
            .selectize-control {
                width: 100%;
                min-width: 0px;
            }
        }
    }
    .notification-list {
        border-right-width: 0px;
        li {
            display: block;
            display: block;
            padding-left: 35px;
            &:first-child {
                padding-left: 35px;
            }
        }
    }
    .navbar-collapse.collapse.in {
        display: block !important;
        border: none;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.logout {
    font-size: 18px;
    color: @primary-content-color;
    padding-left: 10px;
    padding-bottom: 1px;
    margin-top: 7px;
    a {
        color: @primary-content-color !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }
}

.main-content,
.page-wrapper {
    padding-top: 80px;
}

.panel-create //panel-create
{
    .panel-heading {
        background: #f0f0f0;
        font-size: 17px;
        color: #000000;
        line-height: 21px;
        border-width: 0px;
        border-bottom: 1px solid @panel-border-color;
    }
    .panel-body {
        padding: 0px;
        padding-bottom: 7px;
        textarea {
            border: 0px;
            box-shadow: none;
            padding-left: 0px;
            resize: none;
            font-size: 14px;
            padding: 6px 15px;
        }
    }
    .panel-footer {
        background: #FAFBFC;
        padding: 7px 15px;
        border-top-color: @panel-border-color;
        ul {
            margin: 0px;
        }
        .left-list {
            float: left;
            li {
                padding-left: 9px;
                padding-right: 9px;
                &:first-child {
                    padding-left: 0px;
                }
                @media (max-width: 460px) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                @media (max-width: 400px) {
                    padding-left: 4px;
                    padding-right: 4px;
                }
                a {
                    color: #9d9d9d;
                    font-size: 18px;
                    @media (max-width: 350px) {
                        font-size: 15px;
                    }
                }
            }
        }
        .right-list {
            float: right;
            @media (max-width: 424px) {
                width:100%;
                margin-top:5px;
            }
            li {
                padding-left: 8px;
                padding-right: 8px;
                &:last-child {
                    padding-right: 0px;
                }
                @media (max-width: 424px) {
                    width:100%;
                    float:left;
                    padding-left: 0;
                    padding-right: 0;
                    select{
                        width:100%;
                    }
                    .btn{
                        margin-top:5px;
                    }
                }
            }
            .create-album {
                color: #ff6c00;
                font-size: 14px;
                text-decoration: none;
                vertical-align: middle;
                @media (max-width: 424px) {
                    font-size: 13px;
                }
                @media (max-width: 350px) {
                    font-size: 12px;
                }
            }
            .btn {
                padding: 2px 17px;
                @media (max-width: 350px) {
                    padding: 2px 12px;
                    font-size: 12px;
                }
            }
        }
    }
} //panel-create
.panel-post //panel-post
{
    border-width: 0px;
    .panel-heading {
        padding: 14px 15px;
        height: auto;
        position: relative;
        border: 1px solid @panel-border-color!important;
        .post-author {
            position: relative;
            .post-options {
                float: right;
                color: #859AB5;
                margin-top: 6px;
                >ul >li >a {
                    color: #859AB5;
                    border-radius: 83px;
                    font-size: 20px;
                }
                .dropdown-menu {
                    right: -15px;
                    left: auto;
                    li {
                        &:hover {
                            a {
                                background-color: #FAFBFC;
                            }
                        }
                        a {
                            color: @primary-content-color;
                            font-size: @primary-font-size;
                            font-family: @primary-font;
                            i {
                                // font-size: 16px;
                                // margin-right: 7px;
                                width: 20px;
                            }
                        }
                    }
                    .main-link {
                        a {
                            font-weight: 600;
                            font-size: 16px;
                            text-transform: capitalize;
                            padding: 3px 20px 3px 12px !important;
                            @media (max-width: 500px) {
                                font-size: 13px;
                                padding-left: 10px;
                                padding-right: 16px;
                            }
                            @media (max-width: 350px) {
                                font-size: 12px;
                            }
                            .small-text {
                                font-weight: 400;
                                font-size: @primary-font-size;
                                color: @secondary-content-color;
                                display: block;
                                padding-left: 22px;
                                @media (max-width: 500px) {
                                    font-size: 12px;
                                }
                                @media (max-width: 350px) {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                    li.active {
                        a {
                            background-color: #FAFBFC;
                        }
                    }
                }
            }
            .user-avatar {
                width: 50px;
                height: 50px;
                float: left;
                margin-right: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0px;
                }
            }
            .user-post-details {
                line-height: 21px;
                ul {
                    padding-left: 50px;
                }
                li {
                    color: #859AB5;
                    font-size: 16px;
                    font-weight: 400;
                    .user {
                        text-transform: capitalize;
                    }
                    .post-time {
                        color: @primary-content-color;
                        font-size: 12px;
                        letter-spacing: 0px;
                        margin-right: 3px;
                    }
                    &:last-child {
                        font-size: 14px;
                        i {
                            margin-left: 6px;
                            margin-right: 2px;
                        }
                        .post-place {
                            text-transform: capitalize;
                            font-size: 16px;
                            a {
                                color: #000;
                                font-size: 16px;
                            }
                            i {
                                color: #ff6c00;
                            }
                        }
                    }
                }
            }
        }
    }
    .panel-body {
        border: 1px solid @panel-border-color;
        border-top-width: 0px;
        padding-bottom: 7px;
        .text-wrapper {
            p {
                font-family: @primary-font;
                font-size: @primary-font-size;
                font-weight: 400;
                color: #5B6B81;
                letter-spacing: 0.3px;
                line-height: 20px;
                margin-bottom: 0px;
                word-break: break-word;
            }
            .post-image-holder {
                margin-top: 10px;
                max-height: 200px;
                overflow: hidden;
                img {
                    max-width: 100%;
                    max-height: 200px;
                    margin-top: 3px;
                    margin-right: 3px;
                    margin-bottom: 3px;
                    border-radius: 0px;
                    object-fit:cover;
                }
            }
        }
        iframe {
            width: 100%;
            margin-top: 10px;
            height: 273px;
        }
        .actions-count {
            margin-top: 10px;
            margin-bottom: 0px;
            li {
                a {
                    text-decoration: none;
                    font-size: 13px;
                    text-transform: capitalize;
                    color: #859AB5;
                    @media (max-width: 350px) {
                        font-size: 12px;
                    }
                    .count-circle {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        text-align: center;
                        display: inline-block;
                        padding: 0px;
                        background-color: #9FA9BA;
                        line-height: 13px;
                        margin-right: 3px;
                        vertical-align: 2px;
                        i {
                            font-size: 10px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .panel-body.image-post {
        padding: 0px;
        .text-wrapper {
            p {
                padding-top: 15px;
                padding-left: 15px;
                padding-right: 15px;
            }
            .post-image-holder {
                margin-top: 10px;
                max-height: 800px;
                overflow: hidden;
                // padding-bottom: 10px;
                a {
                    width: 100%;
                    height: auto;
                    margin-bottom: 0px;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 0px;
                        margin: 0px;
                    }
                }
            }
        }
        .actions-count {
            padding-left: 15px;
            padding-bottom: 7px;
        }
    }
    .panel-footer.socialite {
        background-color: #fff;
        border-top: 0px;
        padding: 15px;
        border: 1px solid @panel-border-color;
        border-top-width: 0px;
        .footer-list {
            margin-bottom: 0px;
            li {
                padding-left: 14px;
                padding-right: 14px;
                @media (max-width: 350px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                &:first-child {
                    padding-left: 5px;
                }
                &:nth-child(2) {
                    padding-left: 5px;
                }
                a {
                    font-family: @primary-font;
                    font-weight: 400;
                    font-size: @primary-font-size;
                    color: #000;
                    text-transform: capitalize;
                    i {
                        font-size: 16px;
                        color: #7d7d7d;
                        letter-spacing: 0px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    .comments-section {
        width: 100%;
        height: auto;
        padding-left: 4px;
        padding-right: 4px;
        background-color: #eff3f6;
        .comments-wrapper {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 26px;
            height: auto;
            background: #FCFCFC;
            border: 1px solid @panel-border-color;
            border-top-width: 0px;
            .comment-form, .ad-comment-form {
                position: relative;
                .meme-reply {
                    position: absolute;
                    top: 10px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    right: 10px;
                    li {
                        a {
                            i {
                                font-size: 16px;
                                color: #859AB5;
                            }
                        }
                    }
                }
            }
            .to-comment {
                padding-top: 16px;
            }
            .to-comment.comment-reply, .to-comment.ad-comment-reply {
                margin-left: 45px;
                padding-top: 12px;
                .commenter-avatar {
                    width: 30px;
                    height: 30px;
                }
                .comment-textfield {
                    padding-left: 45px;
                    .form-control {
                        height: 30px;
                        font-size: 13px;
                    }
                }
            }
            .commenter {
                margin-top: -3px;
                padding-left: 45px;
            }
            .commenter-avatar {
                width: 36px;
                height: 36px;
                float: left;
                display: inline-block;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                }
            }
            .comment-replies, .ad-comment-replies {
                margin-left: 45px;
            }
            .comment-textfield {
                padding-left: 51px;
                position:relative;
                .form-control {
                    border-radius: 0px;
                    padding-top: 8px;
                    padding-right: 60px;
                    padding-bottom: 8px;
                }
            }
            .comments {
                padding-top: 15px;
            }
            .delete-comment {
                float: right;
                margin-right: 2px;
                font-size: 14px;
                color: @secondary-content-color;
            }
            .edit-comment {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                color: @secondary-content-color;
            }
            .commenter-name {
                a {
                    font-family: @primary-font;
                    font-weight: 400;
                    font-size: 14px;
                    color: #ff6c00;
                    letter-spacing: 0px;
                    text-transform: capitalize;
                }
                .comment-description {
                    font-family: @primary-font;
                    font-weight: 400;
                    font-size: 13px;
                    color: #5B6B81;
                    margin-left: 8px;
                    word-break: break-word;
                }
            }
            .comment-options {
                margin-top: 1px;
                li {
                    padding-left: 4px;
                    padding-right: 4px;
                    &:first-child {
                        padding-left: 5px;
                    }
                    color: #859AB5;
                    a {
                        font-family: @primary-font;
                        font-weight: 400;
                        font-size: 12px;
                        color: #859AB5;
                        text-transform: capitalize;
                        i {
                            margin-right: 3px;
                        }
                    }
                }
            }
            .replies-count {
                margin-top: 13px;
                width: 100px;
                display: block;
                text-transform: capitalize;
                font-family: @primary-font;
                font-weight: 400;
                font-size: @primary-font-size;
                color: #9FA9BA;
                margin-left: 45px;
                i {
                    font-size: 16px;
                    color: #9FA9BA;
                    margin-right: 5px;
                    vertical-align: -1px;
                }
            }
        }
    }
} //panel-post
.btn-follow {
    float: right;
    margin-top: 4px;
    .btn {
        padding: 3px 10px;
    }
}

.media-body.socialte-timeline {
    .text-muted {
        display: block;
        font-size: 14px;
        margin-top: -4px;
    }
}
.comment-image-holder-cntr{
    width: 50%;
    float: left;
    margin-top: 10px;
    img{
        width: auto !important;
        height: 60px !important;
        margin-right: 5px;
        border-radius: 6px;
    }
}
.comment-post-btn-wrap{
    width: 50%;
    float: right;
    text-align: right;
    .comment_post_btn, .ad_comment_post_btn{
        margin-top: 10px;
        font-size: 13px !important;
        text-transform: uppercase !important;
        color: #fff;
        background-color: #ff6c00 !important;
        font-weight: 400 !important;
        padding: 7px 15px !important;
        border-radius: 3px !important;
        border: 0;
        line-height: 1.3;
    }
}
.remove-thumb{
    right: -18px;
    top: 0;
    position: absolute;
}
.embed-responsive-custom a img {
    height: 100px;
    border-radius: 6px;
    margin-top: 5px;
}
//
// cover-section
.timeline-cover-section {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    border: solid 1px #E6EAEE;
    /*   overflow: hidden; */
    @media (max-width: 1200px) {
        margin-bottom: 70px;
    }
    .timeline-cover {
        height: 445px;
        width: 100%;
        overflow: hidden;
        position: relative;
        @media (max-width: 660px) {
            height: 255px;
            width: auto;
        }
        @media (max-width: 480px) {
            height: 155px;
            width: auto;
        }
        @media (max-width: 320px) {
            height: 110px;
            width: auto;
        }
        &:hover {
            .btn-camera-cover {
                display: block;
            }
        }
        img {
            // min-height: 300px;
            height: 100%;
            width: 100%;
            @media (max-width: 660px) {
                /*  height: 255px;
               width: 100%;
               object-fit:cover; */
            }
            @media (max-width: 480px) {
                /*  height: 155px;
               width: 100%;
               object-fit:cover; */
            }
            @media (max-width: 320px) {
                /*  height: 110px;
               width: 100%;
               object-fit:cover; */
            }
        }
        .btn-camera-cover {
            height: 60px;
            background: rgba(0, 0, 0, 0.70);
            border-radius: 0px;
            text-align: left;
            position: absolute;
            top: 8px;
            left: 20px;
            display: none;
            @media (max-width: 560px) {}
            i.fa {
                color: #fff;
                margin-right: 12px;
                font-size: 24px;
                color: #FFFFFF;
                letter-spacing: 0.56px;
                margin-top: 7px;
                @media (max-width: 560px) {
                    font-size: 15px;
                    margin-right: 10px;
                }
            }
            .change-cover-text {
                font-family: @primary-font;
                font-weight: 400;
                text-transform: none;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0.37px;
                vertical-align: text-bottom;
                @media (max-width: 560px) {
                    font-size: 14px;
                }
            }
        }
        // .cover-bottom
        // {
        //  height: 80px;
        //  width: 100%;
        //  position: absolute;
        //  bottom: 0px;
        //  background-image: -webkit-linear-gradient(rgba(39,39,39,0.03) 0%, rgba(27,27,27,0.46) 34%, #121212 100%);
        //  background-image: -o-linear-gradient(rgba(39,39,39,0.03) 0%, rgba(27,27,27,0.46) 34%, #121212 100%);
        //  background-image: linear-gradient(rgba(39,39,39,0.03) 0%, rgba(27,27,27,0.46) 34%, #121212 100%);
        // }
    }
}

.timeline-user-avtar {
    width: 150px;
    height: 150px;
    position: absolute;
    top: -120px;
    left: 20px;
    padding: 7px;
    background-color: #fff;
    @media (max-width: 575.98px) {
        width: 90px;
        height: 90px;
        top: -40px;
        left:10px;
    }
    @media (max-width: 359.98px) {
        width: 90px;
        height: 90px;
        top: -36px;
        left: -23px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        .chang-user-avatar {
            display: block;
        }
    }
}

.event-avatar {
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    top: -63px;
    left: 18px;
    border: 2px solid #fff;
    border-radius: 5px;
    text-align: center;
    .event-month {
        color: #fff;
        text-transform: uppercase;
        margin-top: 1px;
        font-size: 12px;
        line-height: 16px;
    }
    .event-date {
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 3px;
    }
}

.chang-user-avatar {
    position: relative;
    display: none;
    .btn-camera {
        background-color: rgba(0, 0, 0, 0.67);
        width: 100%;
        height: 90px;
        border-radius: 0px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 8px 0 0 0;
        color: #fff;
        @media (max-width: 560px) {
            padding-left: 4px;
            font-size: 8px;
        }
        .fa {
            font-size: 16px;
            margin-top: -1px;
            margin-bottom: 6px;
            vertical-align: middle;
            @media (max-width: 560px) {
                margin-right: 4px;
            }
            @media (max-width: 350px) {
                font-size: 13px;
                margin-top: 5px;
            }
        }
        .avatar-text {
            font-family: @primary-font;
            font-weight: 400;
            font-size: 13px;
            color: #FFFFFF;
            text-transform: capitalize;
            width: 100%;
            line-height: 1;
            position: relative;
            float: left;
            margin-top: -3px;
            vertical-align: top;
            @media (max-width: 560px) {
                font-size: 11px;
            }
            @media (max-width: 350px) {
                font-size: 8px;
            }
            span {
                display: block;
                margin-top: -8px;
                margin-right: 10px;
                @media (max-width: 350px) {
                    margin-top: -7px;
                }
            }
        }
    }
}

.user-timeline-name {
    position: absolute;
    bottom: 22px;
    left: 193px;
    a {
        font-family: @primary-font;
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        text-decoration: none;
        letter-spacing: 0.56px;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
        text-shadow: 3px 0 3px black;

        &::after {
            position: absolute;
            content: ' ';
            z-index: -1;
            height: 7px;
            width: 80%;
            z-index: -1;
            bottom: -2px;
            right: -7%;
            height: 15px;
            width: 100%;
            background: linear-gradient(to right,#ff6b00,#bb5009);
        }
    }
    @media (max-width: 560px) {
        bottom: 16px;
        left: 162px;
    }
    @media (max-width: 350px) {
        bottom: 9px;
        left: 130px;
        a {
            font-size: 22px;
        }
    }
}

.user-timeline-name.event {
    left: 90px;
    bottom: 19px;
}

.status-button {
    position: absolute;
    top: 0px;
    // // bottom: -51px;
    text-align: right;
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: none;
    @media (max-width: 1200px) {
        display: block;
    }
    .btn-status, .btn-invite-xs {
        background-color: #ff6c00;
        color: #fff;
        margin-top: 8px;
        margin-right: 15px;
        @media (max-width: 575.98px) {
            margin-right: 0;
            font-size: 14px;
        }
        @media (max-width: 359.98px) {
            font-size:12px;
        }
    }
    .btn-invite-xs{
        display:none;
        @media (max-width: 1200px) {
            display:inline-block;
        }
        @media (max-width: 575.98px) {
            margin-right: 10px;
        }
    }
}

.user-avatar-progress {
    background-color: rgba(0, 0, 0, 0.67);
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 100%;
    color: #fff;
    text-align: center;
    padding-top: 65px;
    width: 100%;
}

.user-cover-progress {
    background-color: rgba(0, 0, 0, 0.67);
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 100%;
    color: #fff;
    text-align: center;
    padding-top: 150px;
    width: 100%;
}

.timeline-list > ul > li > a .fa-ellipsis-h {
    color: #ff6c00;
    font-size: 18px;
    letter-spacing: 1px;
    :hover {
        color: #000;
    }
}

.timeline-list {
    background-color: #fff;
    /*    height: 50px; */
    position: relative;
    >ul {
        margin-left: 170px;
        text-align: left;
        font-size: 0px;
        margin-bottom: 0px;
        >li {
            border-right: 1px solid @panel-border-color;
            padding-left: 0px;
            padding-right: 0px;
            &:hover {
                >a {
                    color: #ff6c00;
                }
            }
            &:last-child {
                border-right-width: 0px;
            }
            >a {
                display: block;
                padding: 24px 17px;
                font-family: @primary-font;
                font-weight: 600;
                font-size: 16px;
                color: @primary-content-color;
                text-decoration: none;
                text-transform: capitalize;
                background: #fff;
                i {
                    font-size: 15px;
                    vertical-align: bottom;
                }
            }
            .dropdown-menu {
                padding-top: 0px;
                padding-bottom: 0px;
                border-width: 1px solid @panel-border-color;
                border-top: 2px solid #ff6c00;
                border-radius: 0px 0px 4px 4px;
                li {
                    a {
                        text-align: center;
                        color: @primary-content-color;
                        i {
                            margin-right: 10px;
                        }
                    }
                    &:hover {
                        a {
                            color: #fff;
                            background-color: #ff6c00;
                        }
                    }
                }
            }
        }
        .dropdown.open {
            &:hover {
                >a {
                    background: #fff;
                    color: @primary-content-color;
                }
            }
        }
        li.active {
            a {
                color: #ff6c00;
            }
        }
        li.largescreen-report {
            @media (max-width: 1200px) {
                display: none;
            }
            border-right: 1px solid transparent;
        }
        li.smallscreen-report {
            margin-bottom: -60px;
            display: none;
            @media (max-width: 1200px) {
                display: inline-block;
                width: 100%;
                margin-bottom: 0px;
                a {
                    text-align: center;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        height: auto;
        background-color: #EFF3F6;
        >ul {
            margin-left: 0px;
            display: none;
            li {
                border-radius: 4px;
                &:first-child {
                    margin-top: 0px;
                    padding-top: 60px;
                }
                &:last-child {
                    margin-bottom: -60px;
                }
                display: block;
                border-right: 0px;
                margin-top: 10px;
                margin-bottom: 10px;
                >a {
                    border-radius: 4px;
                    padding: 12px 16px;
                    .top-list {
                        // width: 120px;
                        text-align: center;
                        display: block;
                        margin: 0px auto;
                    }
                }
            }
        }
        .report-dropdown {
            li {
                margin-top: 1px;
                &:first-child {
                    padding-top: 0px;
                }
                margin-bottom: 0px;
                a {
                    padding: 8px 16px;
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }
    }
}

.timeline-list.event {
    >ul {
        margin-left: 0px;
    }
}

//user-left-side
.user-profile-buttons {
    .left-col {
        padding-right: 10px;
    }
    .right-col {
        padding-left: 10px;
    }
    .btn {
        @media (max-width: 991px) {
            padding: 9px 16px;
        }
    }
    .btn-profile {
        background-color: #fff;
        border-radius: 4px;
        width: 100%;
        padding: 9px 12px;
        text-align: left;
        text-transform: capitalize;
        @media (max-width: 991px) {
            padding: 12px 17px;
        }
        i.fa {
            // color: red;
            font-size: 25px;
            color: #ff6600;
            letter-spacing: 0px;
            margin-right: 10px;
            position: relative;
            top: 4px;
        }
        font-family: @primary-font;
        font-weight: 600;
        font-size: 16px;
        color: @black;
    }
    .btn-profile.text-center {
        text-align: center;
    }
}

// socialite
.mini-profile.socialite {
    .avatar-img {
        img {
            width: 60px;
            height: 60px;
        }
    }
    .activity-list {
        text-align: center;
        margin-top: 7px;
    }
    .avatar-details {
        padding-left: 80px;
    }
    .activity-name {
        color: #000;
        font-size: 12px;
        font-weight: 600;
    }
    .activity-count {
        color: @secondary-content-color;
    }
}

.user-tag-names {
    display: inline-block;
}

.user-tags-addon {
    .form-group {
        // border-bottom: 1px solid red;
    }
}

// for who are with you
.users-results-wrapper {
    .user-results {
        margin-bottom: 0px;
        border-top: 1px solid @panel-border-color;
        .user-result-item.list-group-item {
            background-color: #fff;
            color: @primary-content-color;
            border-radius: 0px;
            border-width: 0px;
            font-family: @primary-font;
            font-weight: 400;
            font-size: @primary-font-size;
            line-height: 20px;
            padding-left: 10px;
            border-bottom: 2px solid @panel-border-color;
            &:last-child {
                border-bottom-width: 1px;
            }
            &:hover {
                background-color: #FAFBFC;
            }
            img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 5px;
                float: left;
                margin-right: 15px;
            }
            span {
                display: block;
                font-size: 12px;
                color: @secondary-content-color;
            }
        }
    }
}

// typeahead (@mentions)
.typeahead.dropdown-menu {
    padding: 0px;
    min-width: 250px;
    top: 114px;
    li {
        a {
            padding: 0px;
            background-color: #fff;
            padding-bottom: 5px;
            padding-left: 15px;
            padding-top: 5px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 5px;
                float: left;
            }
            .mention_name {
                color: @primary-content-color;
                font-family: @primary-font;
                font-weight: 600;
                margin-left: 5px;
                font-size: @primary-font-size;
            }
            .mention_username {
                color: @secondary-content-color;
                font-weight: 400;
                font-family: @primary-font;
                display: block;
                font-size: 12px;
                padding-left: 50px;
            }
        }
    }
    li.active {
        a {
            background-color: #337ab7;
            .mention_name {
                color: #fff;
            }
            .mention_username {
                color: #fff;
            }
        }
    }
}

select.form-control {
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ffffff url("../images/arrow-new.png") no-repeat 97% 50%;
    background-size: 16px;
}

.group-suggested-users,
.page-suggested-users,
.event-suggested-users {
    .holder {
        padding: 10px 15px;
        .follower {
            img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 4px;
            }
            span {
                color: @primary-content-color;
                font-family: @primary-font;
                margin-left: 4px;
            }
        }
        .follow-links {
            padding-top: 3px;
            .left-col {
                .btn {
                    margin-right: 5px;
                }
            }
        }
    }
}

.page-likes {
    .connect-list {
        .follow-links {
            padding-top: 0px !important;
            .row {
                margin-left: 0px;
                margin-right: 0px;
                .padding-5 {
                    padding: 5px;
                    .btn {
                        width: 100%;
                    }
                    .remove-pagemember {
                        padding-left: 7px;
                    }
                }
            }
            .remove-button {
                display: inline-block;
                width: 95px;
            }
        }
    }
}

.panel {
    .panel-heading {
        height: auto;
    }
}

.holder {
    padding-left: 15px;
}

.soundcloud-results-wrapper {
    .soundcloud-results {
        margin-bottom: 0px;
        .list-group-item:first-child {
            border-radius: 0px;
        }
        .list-group-item {
            border-width: 0px;
            border-top: 1px solid @panel-border-color;
            padding: 10px;
            color: @primary-content-color;
            font-size: @primary-font-size;
            img {
                border-radius: 4px;
                margin-right: 1px;
            }
        }
        a.list-group-item:focus,
        a.list-group-item:hover,
        button.list-group-item:focus,
        button.list-group-item:hover {
            background-color: #fafbfc;
        }
    }
}

.new-conversation {
    padding-left: 15px;
    padding-right: 15px;
    .selectize-dropdown {
        height: auto !important;
        // border-width: 0px !important;
        background-color: #fff;
        border-radius: 0px;
        border: 1px solid @panel-border-color !important;
        border-top-width: 0px;
        .selectize-dropdown-content {
            padding-top: 0px;
            padding-bottom: 0px;
            .big-search-dropdown {
                padding-left: 15px;
                padding-top: 4px;
                .media-left {
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;
                    }
                    padding-right: 15px;
                }
                .media-body {
                    .media-heading {
                        font-family: @primary-font;
                        font-size: @primary-font-size;
                        color: @primary-content-color;
                        letter-spacing: 0px;
                        margin-bottom: 0px;
                        line-height: 19px;
                    }
                    p {
                        font-family: @primary-font;
                        font-size: 12px;
                        color: #9A9FA8;
                        letter-spacing: 0px;
                        max-height: 43px;
                        overflow: hidden;
                        margin-bottom: 0px;
                        height: 18px;
                        overflow: hidden;
                        width: 250px;
                        line-height: 15px;
                    }
                }
            }
            .big-search-dropdown.active {
                background-color: #fafbfc;
                // background-color: #eff2f5;
            }
        }
    }
}

.form-control {
    -webkit-appearance: none !important;
}

.form-group.required {
    label {
        &::after {
            content: '*' !important;
            color: #e76877;
            font-size: 15px;
            margin-left: 3px;
        }
    }
}

.signup-form {
    .form-group {
        label {
            text-transform: capitalize !important;
        }
        .optional {
            font-size: 11px;
            color: #7F8FA4;
            margin-left: 2px;
        }
    }
}

.pagination-holder {
    text-align: center;
    padding-top: 15px;
    .pagination {
        margin: 0px;
    }
}

.table.announcements-table {
    tbody {
        tr {
            td {
                .description {
                    display: block;
                    width: 270px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                ul.list-inline {
                    margin-bottom: 0px;
                }
                .announcement-status {
                    padding: 2px 8px;
                }
            }
            &:last-child {
                td {
                    border-bottom: 0px;
                }
            }
        }
    }
}

.existing-products-table.socialite {
    tr {
        &:last-child {
            td {
                border-bottom: 0px;
            }
        }
    }
}

.announcement-title {
    padding-left: 13px;
    font-size: 14px;
    color: @primary-content-color;
    font-family: @primary-font;
    font-weight: 600;
    .expiry-date {
        font-weight: 400;
        font-size: @primary-font-size;
        padding-right: 10px;
        text-transform: none;
    }
}

.announcement-description {
    padding-left: 13px;
    padding-top: 15px;
    width: 400px;
}

.announcement-actions {
    padding-top: 15px;
    text-align: right;
    .view-by {
        display: block;
        padding-bottom: 10px;
        color: @primary-content-color;
        text-decoration: none;
        font-size: 12px;
        i {
            font-size: 10px;
        }
    }
    a {
        text-transform: capitalize;
    }
}

.btn.btn-success {
    color: #fff !important;
}

.btn.btn-default {
    color: #7F8FA4!important;
}

.modal.fade.in {
    @media (max-width: 700px) {
        padding-right: 0px !important;
    }
    .album-modal.modal-dialog,
    .modal-likes.modal-dialog {
        margin: 30px auto;
        @media (max-width: 510px) {
            margin: 0px auto;
            width: 380px;
            .modal-content {
                margin-top: 50px;
                .album-body {
                    padding: 10px 16px;
                    .form-group {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        @media (max-width: 400px) {
            width: 330px;
            .modal-content {
                margin-top: 20px;
            }
        }
        @media (max-width: 350px) {
            width: 300px;
        }
    }
}

.time-created {
    color: @secondary-content-color;
}

.largescreen-message {
    @media (max-width: 1010px) {
        display: none !important;
    }
}

.smallscreen-message {
    display: none !important;
    @media (max-width: 1010px) {
        display: block !important;
    }
}

.dropdown-loading {
    text-align: center;
    i {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

[v-cloak] {
    display: none;
}

.tooltip-inner {
    white-space: nowrap;
}

// .input-group
// {
//  &:hover
//  {
//      .input-group-addon
//      {
//          // border: 1px solid @input-selected-border-color;
//      }
//  }
//  .form-control
//  {
//          &:focus
//          {
//          & + .input-group-addon
//          {
//              border-color: @input-selected-border-color;
//          }
//          }
//  }
// }
.installer-container {
    padding-top: 80px;
}

.settings-content {
    min-height: 300px;
    padding-bottom: 60px;
    p {
        text-align: center;
        font-size: 22px;
        padding-top: 100px;
    }
    .btn-installer {
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        margin-left: -45px;
    }
    .fa {
        font-size: 24px !important;
        &.success {
            color: @success-color;
        }
        &.error {
            color: @danger-color;
        }
    }
}

.installer-form {
    text-align: center;
    .btn {
        margin: 10px 0px;
    }
}

.input-group.input-group-focus {
    .input-group-addon.addon-left {
        border: 1px solid @input-selected-border-color;
        border-right-width: 0px;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
        transition-property: border-color, box-shadow !important;
        transition-duration: 0.15s, 0.15s !important;
        transition-timing-function: ease-in-out, ease-in-out !important;
        transition-delay: initial, initial !important;
    }
    .form-control {
        border-top: 1px solid @input-selected-border-color;
        border-bottom: 1px solid @input-selected-border-color;
    }
    .input-group-addon.addon-right {
        border: 1px solid @input-selected-border-color;
        border-left-width: 0px;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
        transition-property: border-color, box-shadow !important;
        transition-duration: 0.15s, 0.15s !important;
        transition-timing-function: ease-in-out, ease-in-out !important;
        transition-delay: initial, initial !important;
    }
}

.badge-verification {
    padding-left: 10px;
    padding-top: 5px;
    a {
        display: block;
        position: relative;
        .verified-badge {
            width: 15px;
            height: 15px;
            position: absolute;
            top: -3px;
            left: -6px;
            line-height: 10px;
            .fa {
                font-size: 11px;
            }
        }
    }
}

.list-details {
    li {
        padding-top: 5px;
    }
}

.event-avatar.settings {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: 10px;
    .event-date {
        margin-top: 0px;
        font-size: 13px;
    }
    .event-month {
        margin-top: 0px;
        font-size: 10px;
        line-height: 9px;
    }
}

.avatar-details.event-settings {
    padding-left: 17px!important;
}

.report-dropdown {
    li {
        border-bottom: 1px solid #E6EAEE;
        a {
            padding: 8px 20px;
        }
    }
}

.nav-tabs.events {
    margin-top: 15px;
    margin-left: 10px;
    li {
        a {
            padding-left: 8px;
            padding-right: 8px;
            font-size: 11px;
        }
    }
}

.tab-content.events {
    padding: 20px 15px;
    border-width: 0px;
    .my-best-pictures {
        padding: 0px;
    }
}

//events
.best-pictures {
    .new-events {
        .list-group-item {
            border-top: transparent;
            padding: 4px 4px;
            a {
                img {
                    width: 34px;
                    height: 34px;
                    border-radius: 4px;
                }
            }
            .date {
                padding-left: 20px;
                padding-top: 7px;
            }
        }
    }
}

input:focus::-webkit-input-placeholder {
    color: transparent !important;
}

input:focus:-moz-placeholder {
    color: transparent !important;
}


/* FF 4-18 */

input:focus::-moz-placeholder {
    color: transparent !important;
}


/* FF 19+ */

input:focus:-ms-input-placeholder {
    color: transparent !important;
}


/* IE 10+ */

input[type="text"]:focus::-webkit-input-placeholder {
    color: transparent !important;
}

input[type="text"]:focus:-moz-placeholder {
    color: transparent !important;
}


/* FF 4-18 */

input[type="text"]:focus::-moz-placeholder {
    color: transparent !important;
}


/* FF 19+ */

input[type="text"]:focus:-ms-input-placeholder {
    color: transparent !important;
}


/* IE 10+ */
