body {
    font-family: 'Lato', sans-serif;
}


/*container width modifications*/

@media (min-width: 1360px) {
    .container {
        width: 1330px;
    }
}

a,
.btn,
.box-slider-arrow {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
}

.btn:hover,
.main-footer__links li a:hover,
.get-yudu-from a:hover {
    opacity: .8;
    text-decoration: none;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff !important;
}
/*polygon clipping*/

.get-yudu-outer,
.wider-section,
.inner-content-wrap {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 52% 0, 50% 30px, 48% 0%, 0 0);
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 52% 0, 50% 30px, 48% 0%, 0 0);
    margin-top: -90px;
}

input:focus::-webkit-input-placeholder { color:transparent !important; }
input:focus:-moz-placeholder { color:transparent !important; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent !important; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent !important; } /* IE 10+ */

/*button*/

.btn {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    border-radius: 3px !important;
}

.btn.btn--solid-orange {
    border-color: #ff6c00 !important;
    background: #ff6c00 !important;
    color: #fff !important;
}

.btn.btn--solid-grey {
    border-color: #2a2a2a !important;
    background: #2a2a2a !important;
    color: #fff !important;
}

.btn-outline-orange-medium {
    font-size: 14px !important;
    text-transform: uppercase !important;
    color:#ff6c00 !important;
    background-color: #fff !important;
    border:1px solid #ff6c00 !important;
    font-weight: 400 !important;
    padding: 12px 30px !important;
    padding: 6px 29px !important;
    border-radius: 3px !important;
}

.btn-outline-orange-medium:hover {
    opacity: .8;
    color:#ff6c00 !important;
}

.navbar-btn-join {
    min-width: 100px;
    /* padding: 8px 10px !important; */
}

.navbar-btn-login {
    width: 100px;
    padding: 8px 10px !important;
}


/*header*/

.navbar-custom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1030;
}

.navbar-custom .navbar-brand {
    height: auto;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 9px 15px 7px 15px !important;
}
@media( max-width:991.98px){
    .navbar-custom .navbar-brand{
        padding: 0px 15px 7px 15px !important;
    }

}
.navbar-custom {
    background-color: transparent;
    border: 0;
    border-radius: 0;
}

.navbar-custom .navbar-right {
    margin-top: 27px;
}

.navbar-custom .navbar-right li {
    margin-left: 7px;
}


/*banner*/

.home-banner {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.home-banner__slide {
    float: left;
    width: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #284654;
}

.home-banner__trow {
    display: table;
    vertical-align: middle;
    width: 100%;
    float: left;
    table-layout: fixed;
}

.home-banner__tcell {
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 50px;
}

.main-header {
    float: left;
    width: 100%;
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    margin-top: 0;
}

.home-banner__btn {
    font-size: 22px;
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
    border: 1px solid #8d969a !important;
    padding: 17px 45px;
    min-width: 363px;
    max-width: 100%;
}

.home-banner__slide--1 {
    background-image: url('../img/slide1-bg.jpg');
}

.home-banner__btn-wrap {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 30px;
}

.section-header {
    float: left;
    width: 100%;
    font-size: 42px;
    font-weight: 700;
    color: #000000;
    position: relative;
    text-align: center;
    padding-bottom: 16px;
    margin-bottom: 60px;
    margin-top: 0;
}

.section-header:before {
    content: '';
    position: absolute;
    width: 272px;
    height: 1px;
    background: #989898;
    left: 50%;
    bottom: 1px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.section-header:after {
    content: '';
    position: absolute;
    width: 52px;
    height: 3px;
    background: #ff6000;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.section-sub-header {
    float: left;
    width: 100%;
    font-size: 40px;
    color: #ff6000;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px;
}

.section-para {
    float: left;
    width: 100%;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.4;
    color: #414548;
}

.bullet-list {
    float: left;
    width: 100%;
    padding: 0;
    margin: 20px 0;
    list-style: none;
}

.bullet-list > li {
    float: left;
    width: 100%;
    list-style: none;
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 22px;
    padding-left: 20px;
    position: relative;
}

.bullet-list > li:before {
    content: '';
    position: absolute;
    border: 5px solid transparent;
    border-left-color: #000;
    left: 0;
    top: 7px;
}

.wider-section {
    float: left;
    width: 100%;
    min-height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 110px 0 120px 0;
}

.wider-section--what-we-offer {
    background-image: url('../img/section-1-bg.jpg');
    background-position: center bottom;
}

.wider-section--why-yudu {
    background-image: url('../img/section-2-bg.jpg');
    background-position: center bottom;
    overflow: hidden;
}

.box-slider-frame {
    float: left;
    width: 100%;
    position: relative;
}

.box-slider-frame__slide {
    float: left;
    padding: 0 44px;
    display: none;
}

.slick-initialized .box-slider-frame__slide {
    display: block;
}

.sliding-card {
    float: left;
    width: 100%;
    text-align: center;
    background: #fff;
}

.sliding-card__thumbnail {
    position: relative;
    float: left;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
    width: 100%;
    background: #000;
    cursor: pointer;
}

.sliding-card__thumbnail:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 25px;
    width: 54px;
    height: 54px;
    background: transparent url('../img/play.png') no-repeat center;
}

.sliding-card__header {
    float: left;
    width: 100%;
    padding: 10px 10px;
    background: #ff6000;
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px;
    margin-top: -35px;
    position: relative;
}

.sliding-card__data {
    float: left;
    width: 100%;
    padding: 0 20px 35px 20px;
}

.sliding-card__data p {
    position: relative;
    float: left;
    width: 92%;
    left: 4%;
    font-size: 15px;
    color: #000000;
    line-height: 1.85;
}

.sliding-card__btn-wrap .btn-default {
    background: transparent !important;
    font-size: 13px;
    color: #000;
    border: 1px solid #7a7a7a !important;
    padding: 6px 20px;
}

.sliding-card__btn-wrap {
    margin-top: 5px;
    float: left;
    width: 100%;
}

.slick-slider .slick-track,
.slick-slider .slick-list,
.slick-slide {
    outline: none !important;
}

.box-slider-arrow {
    position: absolute;
    width: 37px;
    height: 67px;
    top: 50%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5;
    z-index: 100;
    cursor: pointer;
}

.box-slider-arrow:hover {
    opacity: 1;
}

.box-slider-arrow.prev {
    left: -20px;
    right: auto;
    background-image: url(../img/slide-nav-left.png);
}

.box-slider-arrow.next {
    right: -20px;
    left: auto;
    background-image: url(../img/slide-nav-right.png);
}

.get-yudu-outer {
    position: relative;
    float: left;
    width: 100%;
    background: #1a1c29 url(../img/section-3-bg.jpg) no-repeat center;
    background-size: cover;
    padding: 100px 0 110px 0;
}

.get-yudu-header {
    float: left;
    width: 100%;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
    line-height: 1.25;
    margin-bottom: 45px;
}

.get-yudu-from {
    float: left;
    width: 100%;
}

.get-yudu-from .disable {
    opacity: .8;
    cursor: not-allowed;
}

.coming_soon {
    position: relative;
    width: 100%;
    float: left;
    font-size: 18px;
    color: #fff;
}

.get-yudu-from a {
    float: left;
    margin-right: 35px;
    margin-bottom: 10px;
}

.get-yudu-from a:last-child {
    margin-right: 0;
}

.get-yudu-from a img {
    float: left;
}

.main-footer {
    float: left;
    width: 100%;
    background: #000;
    padding: 40px 0;
}

.footer-logo {
    display: inline-block;
    vertical-align: top;
}

.main-footer__links {
    float: left;
    width: 100%;
    list-style: none;
    margin: 52px 0 10px 0;
    padding: 0;
}

.main-footer__links li {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 5px 20px;
}

.main-footer__links li a {
    float: left;
    font-size: 18px;
    color: #fcfcfc;
}

.sub-footer__links {
    float: left;
    width: 100%;
    list-style: none;
    margin: 10px 0 30px 0;
    padding: 0;
}

.sub-footer__links li {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 2px 20px;
}

.sub-footer__links li a {
    float: left;
    font-size: 15px;
    color: #8e8e8e;
}

.sub-footer__links li a:hover {
    opacity: .7;
}

.main-footer__copy {
    float: left;
    width: 100%;
    font-size: 12px;
    color: #71787E;
    margin-bottom: 15px;
}

.main-footer__social-share {
    float: left;
    width: 100%;
    list-style: none;
    margin: 28px 0;
    padding: 0;
}

.main-footer__social-share > li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
}

.main-footer__social-share > li a {
    float: left;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #1c2024;
    background: #8c8c8c;
    border-radius: 50%;
    overflow: hidden;
    font-size: 20px;
    line-height: 50px;
    margin-right: 10px;
    margin-left: 10px;
}

.main-footer__social-share > li a:hover {
    background: #fff;
}

.sub-banner {
    float: left;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sub-banner__row {
    display: table;
    float: left;
    table-layout: fixed;
    vertical-align: middle;
    width: 100%;
}

.sub-banner__cell {
    display: table-cell;
    vertical-align: middle;
    height: 372px;
    width: 100%;
    text-align: center;
}

.sub-banner__page-header {
    float: left;
    width: 100%;
    font-size: 60px;
    font-weight: 700;
    color: black;
}

.sub-banner--about {
    background-color: #655a42;
    background-image: url(../img/inner/about-banner-bg.jpg);
}

.sub-banner--team {
    background-color: #655a42;
    background-image: url(../img/inner/team-banner-bg.jpg);
}

.sub-banner--story {
    background-color: #655a42;
    background-image: url(../img/inner/story-banner-bg.jpg);
    background-position: top center;
}

/* .sub-banner--contact {
    background-color: #655a42;
    background-image: url(../img/inner/contact-banner-bg.jpg);
    background-position: top center;
} */

.sub-banner--download-app {
    background-color: #2b1400;
    background-image: url(../img/inner/download-app-banner-bg.png);
    background-position: top center;
    height: 667px;
    display: flex;
    align-items: flex-end;
}
.download-app-header{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top:100px;
    float: left;
}
.download-app-header h1{
    font-size: 46px;
    line-height: 58px;
    color: #fff;
    margin:0;
    position: relative;
    width: 100%;
    float: left;
}
.download-app-button-holder{
    position: relative;
    width: 100%;
    float: left;
    margin-top:38px;
}
.download-app-button-holder a{
    margin-left:28px;
}
.download-app-button-holder a:first-child{
    margin-left:0px !important;
}
.yudu-app{
    text-align: right;
}
@media(max-width:1199.98px){
    .download-app-header h1{
        font-size: 40px;
    }
}
@media( max-width:991.98px){
    .sub-banner--download-app{
        align-items: center;
        /* height: auto;
        display: block;
        padding-top: 100px; */
    }
    .download-app-header{
        margin-top: 0px;
    }
    .yudu-app{
        display: none;
        /* text-align: left;
        margin-top:30px; */
        display: none;
    }
}
@media( max-width:510px){
    .download-app-button-holder a{
        float: left;
        margin-left:0px;
        margin-bottom: 20px;
    }
    .download-app-header h1 {
        font-size: 35px;
        line-height: 1.1;
    }
}
@media( max-width:320px){
    .download-app-header h1 {
        font-size: 30px;
        line-height: 1.1;
    }
}
.sub-banner--privacy {
    /* background-color: #655a42;
    background-image: url(../img/inner/privacy-banner-bg.jpg); */
    background-position: top center;
}

.sub-banner--privacy .sub-banner__cell,
.sub-banner--term .sub-banner__cell,  
.sub-banner--contact .sub-banner__cell{
    padding-top: 90px;
}

@media (max-width: 767px) {
    .sub-banner--privacy .sub-banner__cell,
    .sub-banner--term .sub-banner__cell,
    .sub-banner--contact .sub-banner__cell {
        padding-top: 0;
    }

    .sub-banner--privacy .sub-banner__page-header,
    .sub-banner--term .sub-banner__page-header,
    .sub-banner--contact .sub-banner__page-header {
        margin-top: 47px;
        line-height: 1.2;
    }  
}

/* .sub-banner--term {
    background-color: #655a42;
    background-image: url(../img/inner/term-banner-bg.jpg);
    background-position: top center;
} */

.modal-content {
    border-radius: 0;
    border: 0;
    padding-bottom: 50px;
}

.inner-content-wrap {
    position: relative;
    float: left;
    width: 100%;
    padding: 110px 0 120px 0;
    background: #fff;
}

.inner-content-wrap--about {
    background-color: #fff;
    padding: 0px;
}

.about-intro-wrap h2 {
    font-size: 27px;
    font-weight: bold;
    color: #ff6c00;
    width: 80%;
    line-height: 1.3;
}

.about-intro-wrap h4 {
    position: relative;
    float: left;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
}

.about-intro-wrap p {
    color: #696969 !important;
}

.inner-wrap-box {
    width: 100%;
    float: left;
    padding: 20px;
    margin: 50px 0px;
}

.inner-wrap-box h3 {
    color: #000;
    font-weight: bold;
}

.inner-wrap-box p {
    margin-bottom: 30px;
}

.inner-content-wrap--about .inner-wrap-box ul {
    margin: 0px;
}

.inner-content-wrap--about .inner-wrap-box ul > li {
    color: #ff6c00;
    font-size: 15px;
    position: relative;
    list-style: none;
}

.inner-content-wrap--about .inner-wrap-box ul > li:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0px;
    top: 12px;
    background-color: #ff6c00;
    float: left;
}

.about-top-section {
    position: relative;
    width: 100%;
    padding: 80px 0px 80px 0px;
    background-color: #f8f8f8;
    float: left;
}

.inner-content-wrap--about .inner-wrap-box ol {
    margin-top: 20px;
    padding: 0px;
}

.inner-content-wrap--about .inner-wrap-box ol > li {
    color: #000;
    width: 95%;
    font-size: 15px;
    position: relative;
    list-style: none;
    margin-bottom: 20px;
}

.inner-content-wrap--team {
    padding: 80px 0px 110px 0px;
}

.inner-content-wrap--privacy h4 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
}

.inner-content-wrap--privacy p {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #000000;
    line-height: 1.8;
}

.inner-content-wrap--privacy ul {
    float: left;
    list-style: none;
    width: 100%;
    margin: 20px 0;
    padding: 0;
}

.inner-content-wrap--privacy ul > li {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #000000;
    line-height: 1.8;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
}

.inner-content-wrap--privacy ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    overflow: hidden;
}

.team-box {
    margin: 30px 0px 0px 0px !important;
}

.team-box p {
    font-size: 18px !important;
    color: #000;
    line-height: 1.6;
}

.team-top-box {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    float: left;
}

.team-top-box .team-left-img {
    position: relative;
    width: 30%;
    float: left;
    padding-right: 15px;
}

.team-top-box .team-right-pos {
    position: relative;
    width: 70%;
    float: left;
}

.team-top-box .team-left-img img {
    max-width: 100%;
    padding: 10px;
    border: 1px solid #FF6C00;
}

.team-top-box .team-right-pos h3 {
    font-size: 30px;
    color: #000;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 3px;
    margin-top: 0px;
}
.admin-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media( max-width:767.98px){
    .admin-flex{
        display: block;
    }
    .team-top-box .team-left-img{
        padding-right: 0;
    }
}
.team-top-box .team-right-pos h5 {
    margin-bottom: 10px;
    margin-top:0px;
    font-size: 20px;
    color: #ff6c00;
    font-weight: 500;
}

.inner-content-wrap--about .inner-wrap-box ol > li strong {
    color: #ff6c00;
}

.inner-content-wrap--about h4 {
    position: relative;
    float: left;
    width: 100%;
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    margin-bottom: 12px;
}

.inner-content-wrap--about p {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #000000;
    line-height: 1.8;
}

.inner-content-wrap--story h3 {
    position: relative;
    float: left;
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 30px;
    margin-top: 0px;
}

.inner-content-wrap--story h4 {
    position: relative;
    float: left;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 30px;
    margin-top: 0px;
}

.inner-content-wrap--story p {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #737373;
    line-height: 1.8;
    margin-bottom: 20px;
}

.image-content-frame {
    float: left;
    display: table;
    vertical-align: top;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 50px;
}

.image-content-frame__cell {
    float: none;
    display: table-cell;
    vertical-align: top;
}

.image-content-frame__cell:first-child {
    width: 430px;
    padding-right: 30px;
}

.image-content-frame__cell h3 {
    font-size: 25px;
    font-weight: normal;
    color: #000000;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 12px;
}

.image-content-frame__cell img {
    max-width: 100%;
}

.inner-content-wrap__sub-block {
    margin-bottom: 30px;
}

.inner-content-wrap--contact .form-group .control-label {
    font-size: 15px;
    color: #000;
    font-weight: bold;
    text-transform: capitalize;
}

.inner-content-wrap--contact .form-group .form-control {
    height: 43px;
    background-color: #fff;
    border: solid 1px #afafaf;
    font-size: 15px;
    text-transform: capitalize;
}

.inner-content-wrap--contact .form-group .form-control::-webkit-input-placeholder {
    color: #afafaf;
}

.inner-content-wrap--contact .form-group .form-control:-moz-placeholder {
    color: #afafaf;
    opacity: 1;
}

.inner-content-wrap--contact .form-group .form-control::-moz-placeholder {
    color: #afafaf;
    opacity: 1;
}

.inner-content-wrap--contact .form-group .form-control:-ms-input-placeholder {
    color: #afafaf;
}

.inner-content-wrap--contact .form-group textarea.form-control {
    height: 150px;
}

.inner-content-wrap--contact .has-error input[type="text"],
.inner-content-wrap--contact .has-error textarea,
.inner-content-wrap--contact .has-error input[type="email"] {
    border: solid 1px #ff0000 !important;
}

.inner-content-wrap--contact .has-error .help-block {
    color: #ff0000;
}

.btn-orange {
    padding: 12px 60px;
    background-color: #ff6c00;
    border-radius: 2px;
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, .59);
}

.btn-orange:hover,
.btn-orange:focus {
    color: #fff;
}

.btn-orange-outline {
    padding: 4px 12px;
    background-color: #fff;
    border: solid 1px #ff6c00;
    border-radius: 2px;
    font-size: 14px;
    color: #ff6c00;
    margin-top: 20px;
    font-weight: 400;
    text-transform: capitalize;
}

.btn-orange-small {
    padding: 8px 40px;
    background-color: #ff6c00;
    border-radius: 2px;
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 600;
    text-transform: capitalize;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, .59);
}

.btn-orange-small:hover,
.btn-orange-small:focus {
    color: #fff;
}

.mt20 {
    margin-top: 20px;
}

.btn-orange-outline:hover {
    background-color: #ff6c00;
    color: #fff;
}

.btn-orange-outline:first-child {
    margin-right: 6px;
}
.signature {
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    color: #ff6600;
    margin-top: 20px;
    position: relative;
    width: 100%;
    float: left;
}
.form-month{
    width:44%;
    margin-right:3%;
    float: left;
}
.form-day{
    width:20%;
    margin-right:3%;
    float: left;
}
.form-year{
    width:30%;
    float: left;
}
.dob label{
    width:100%;
}

@media (max-width: 1199px) {
    .main-header {
        font-size: 62px;
    }
    .section-sub-header {
        font-size: 40px;
    }
    .section-header {
        font-size: 40px;
    }
    .get-yudu-header {
        font-size: 38px;
    }
    .box-slider-frame__slide {
        padding-left: 10px;
        padding-right: 10px;
    }
    .sub-banner__cell {
        height: 462px;
    }
}

@media (max-width: 991px) {
    .main-header {
        font-size: 52px;
    }
    .section-sub-header {
        font-size: 35px;
    }
    .section-header {
        font-size: 35px;
    }
    .get-yudu-header {
        font-size: 33px;
    }
    .get-yudu-from a img {
        width: 200px;
    }
    .get-yudu-from a {
        margin-right: 15px;
    }
    .home-banner__btn {
        min-width: 0;
        font-size: 22px;
    }
    .sliding-card__header {
        font-size: 25px;
    }
    .image-content-frame {
        float: left;
        display: block;
    }
    .image-content-frame__cell {
        float: left;
        display: block;
        width: 100% !important;
    }
    .image-content-frame__cell:first-child {
        padding-right: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 1010px) {
    .navbar-custom .navbar-right {
        margin-right: -15px !important;
    }
    .navbar-custom .navbar-right > li {
        float: left;
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    /* .navbar.socialite{
        height: auto;
    } */
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .navbar-custom .navbar-right > li .dropdown-menu{
        width: 100% !important;
    }
    .navbar-custom .navbar-right.navbar-right--abs{
        position: absolute !important;
        right: 15px !important;
        margin-right: 0 !important;
        display: inline-block !important;
        top: 0 !important;
    }
    .navbar-custom .navbar-right.navbar-right--abs > li{
        margin-left: 10px !important;
        width: auto !important;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .main-header {
        font-size: 42px;
    }
    .navbar-btn-login {
        width: auto;
    }
    .navbar-btn-join {
        width: auto;
    }
    .home-banner__btn {
        font-size: 20px;
    }
    .sub-banner__cell {
        height: 362px;
    }
    .team-top-box .team-left-img,
    .team-top-box .team-right-pos {
        width: 100%;
    }
    .team-top-box .team-left-img img {
        max-width: 100%;
    }
    .team-top-box .team-right-pos h3 {
        font-size: 22px;
        margin-left: 0px;
        margin-top: 5%;
    }
    .team-top-box .team-right-pos h5 {
        font-size: 15px;
        margin-left: 0px;
    }
    .main-footer__links {
        margin: 30px 0 10px 0;
    }
    .main-footer__links li {
        margin: 5px 10px;
    }
    .main-footer__links li a {
        font-size: 16px;
    }
    .main-footer__social-share > li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 5px;
        margin-left: 5px;
    }
    .get-yudu-outer,
    .wider-section,
    .inner-content-wrap {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 55% 0, 50% 25px, 45% 0%, 0 0);
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 55% 0, 50% 25px, 45% 0%, 0 0);
    }
}

@media (max-width: 480px) {
    .main-header {
        font-size: 38px;
    }
    .section-sub-header {
        font-size: 32px;
    }
    .section-header {
        font-size: 32px;
    }
    .get-yudu-header {
        font-size: 30px;
    }
    .sliding-card__header {
        font-size: 22px;
    }
    .main-footer__links {
        margin: 20px 0 10px 0;
    }
    .main-footer__links li {
        margin: 5px 10px;
    }
    .main-footer__links li a {
        font-size: 14px;
    }
    .main-footer__social-share > li a {
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin-right: 4px;
        margin-left: 4px;
    }
    .main-footer__social-share {
        margin: 10px 0;
    }
    .sub-footer__links {
        margin: 10px 0 20px 0;
    }
    .sub-footer__links li {
        margin: 2px 6px;
    }
    .main-footer__copy {
        font-size: 12px;
    }
}

.mce-primary {
    background: #2d8ac7 !important;
}

.inner-content-wrap--about ul {
    float: left;
    list-style: none;
    width: 100%;
    margin: 20px 0;
    padding: 0;
}

.inner-content-wrap--about ul > li {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #000000;
    line-height: 1.8;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
}

.inner-content-wrap--about ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    overflow: hidden;
}

.profile-image-placeholder {
    display: inline-block;
    width: 250px;
    height: 250px;
    background: #b9b9b9;
}

.image-content-frame--team .image-content-frame__cell:first-child {
    width: 300px;
    max-width: 100%;
}


/* Business Page */
.business-page-left-bar{
    padding: 0px 0px 0px 21px !important;
    background-color: #fbfbfb;
}
.business-page-left-bar ul li{
    padding:7px 0 !important;
    display: flex;
    align-items: center;
}
.business-page-left-bar ul li:first-child{
    border-top: none !important;
}
.business-page-left-bar ul li a{
    color: #000;
}
.business-page-left-bar ul li:hover svg path{
    fill: #000;
}

.business-page-left-bar ul li.active svg path{
    fill: #000;
}

.business-page-right .panel-title{
    width: 100%;
    float: left;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
    padding: 0 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
}
.advt-summary-head{
    font-size: 14px ;
    color: #000 ;
    font-weight: 400 ;
    font-family: 'Source Sans Pro', sans-serif !important;
    margin: 0 0 15px;
}
.advt-summary-head span{
    font-weight: 600;
}
.advt-summary-dropdown{
    color: #000;
    font-size: 14px;
    line-height: 1;
}
.advt-summary-dropdown .dropdown-toggle{
    position: relative;
    padding-right: 15px;
    color: #000;
}
.advt-summary-dropdown .dropdown-toggle:Before{
    content: "";
    position: absolute;
    right: 0;
    top: 5px;
    margin-left: 2px;
    vertical-align: middle;
    border-bottom: 4px solid #FF6C00;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.advt-summary-dropdown .dropdown-toggle:after{
    content: "";
    position: absolute;
    right: 0;
    top: 11px;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid #FF6C00;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.advt-summary-dropdown .dropdown-menu{
    right: 0;
    left: auto;
}
.business-page-right .panel-heading.panel-settings{
    padding: 10px 15px !important;

}
.business-page-right .panel-heading.panel-settings .btn-orange-medium{
    font-size: 12px !important;
}
.business-data-wrap .widget-card{
    padding: 0;
    background-color: #fff;
}
.business-data-wrap .widget-card .widget-card-project {
    width: 100%;
    float: left;
    padding: 10px 15px;
}
.business-data-wrap .widget-card .widget-card-project .bridge-text {
    padding-top: 0px;
}
.ads-not-approved{
    color:#DA0000;
}
.ads-active{
    color:#2da1f8;
}
.ads-paused{
    color:rgb(255, 135, 50);
}
.ads-completed{
    color:#69B958;
}
.business-data-wrap .widget-card .widget-card-project .bridge-text a{
    font-size: 12px;
    color: #000;
}
.business-data-wrap .widget-card .widget-card-project .upadate-project{
    font-size: 10px;
    padding: 2px 5px;
    background-color: #F7F7F7;
    margin: 2px 0;
    width: 100%;
    float: left;
    color: #000;
}
.business-data-wrap .widget-card .widget-card-project .upadate-project span{
    float: right;
}
.upadate-project-description{
    font-size: 10px;
    padding: 0;
    margin:0 0 2px 0;
    width: 100%;
    float: left;
    color: #000;
    font-weight: 600;
}
.ads-box-list-wrap{
    width: 100%;
    float: left;
    margin-top: 14px;
}
.business-data-wrap .widget-card .widget-card-project .ads-box-list-wrap-rejected{
    padding: 10px;
    padding-left: 35px;
    background-color: #FFF5F5;
    margin:6px 0 2px ;
    width: 100%;
    float: left;
    color: #000;
    font-size: 11px;
    font-weight: 600;
    position: relative;
    background-image: url(../img/ad-center/ad-rejected.svg);
    background-position: 10px center;
    background-repeat: no-repeat;
}
.business-data-wrap .widget-card .widget-card-project .ads-box-list-wrap-rejected::after{
    content: "";
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(../img/ad-center/arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 7px;
}









.border-bottom-grey{
    border-bottom: solid 1px #E6EAEE !important;
}
.business-page-right .panel-body .socialite-icon{
    width:58px;
    height: 58px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.business-page-right .list-group.socialite-group .list-group-item{
    padding:17px !important;
    display: flex;
    align-items: center;
}
.business-page-right .list-group-navigation .list-group-item .list-text{
    margin-left: 10px !important;
    width: 68% !important;
    float: left;
}
.business-page-right .list-group-navigation .list-group-item .list-text a{
    position: relative;
}
.business-page-right .list-group-navigation .list-group-item .list-text p{
    font-weight: 400;
    line-height: 1.3;
    font-size: 10px;
    color: #000;
    word-wrap: break-word;
}
.business-page-right .list-group-navigation .list-group-item .price{
    position: relative;
    float: left;
    width: 25%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    color: #7e7e7e;
}
.business-page-right .sub-heading{
    font-size: 14px;
    color: #828282;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 10px 0;
}
.business-data-wrap{
    position: relative;
    width: 100%;
    padding: 14px;
    float: left;
    font-family: 'Source Sans Pro', sans-serif;
}
.business-data-wrap .top-wrap{
    position: relative;
    width: 100%;
    float: left;
}
.advt-summary-block{
    width: 100%;
    float: left;
    background-color: #fff;
    border: 1px solid #ECECEC;
    padding: 10px 15px;
    padding-left: 50px;
    position: relative;
    background-repeat: no-repeat;
}
.advt-summary-block.reached{
    background-image: url(../img/ad-center/reached.svg);
    background-position: 10px center;
}
.advt-summary-block.post{
    background-image: url(../img/ad-center/post.svg);
    background-position: 10px center;
}
.advt-summary-block.link{
    background-image: url(../img/ad-center/link-clocks.svg);
    background-position: 10px center;
}
.advt-summary-block-count{
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;
}
.advt-summary-block-label{
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.2;
}
.business-data-wrap .top-left-wrap{
    position:relative;
    width:80%;
    float:left;
}
.business-data-wrap .top-right-wrap{
    position:relative;
    width:20%;
    float:left;
    display: flex;
    justify-content: flex-end;
}
.cursor-pointer{
    cursor: pointer;
}
.business-data-wrap .top-right-wrap .cursor-pointer b{
    margin:0 10px;
}
.px-0{
    padding-left:0px;
    padding-right: 0px;
}
.business-data-wrap .form-control{
    padding-left: 0px;
}
.business-data-wrap .form-control[readonly]{
    background-color: transparent !important;
    border: none !important;
    color: #000 !important;
}
.business-data-wrap .input-group .input-group-addon{
    background-image: none;
    border: none;
    background-color: transparent;
}
.contact-wrapper{
    position: relative;
    width: 100%;
    padding-top:15px;
    float: left;
}
.contact-wrapper .input-group .input-group-addon{
    color: #000;
}
.contact-wrapper .input-group{
    margin-bottom: 10px;
}
.contact-wrapper .input-group .form-control{
    border-radius: 0px;
    padding-left: 8px;
}
.settings-wrapper{
    position: relative;
    width: 100%;
    float: left;
}
.settings-wrapper .help-block{
    padding:0;
    background: none;
}
.settings-wrapper .panel{
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.settings-wrapper .panel-heading{
    position: relative;
    width: 100%;
    float: left;
    background-image: none;
    border: none !important;
    border-bottom: solid 1px #e9e9e9 !important;
}
.settings-wrapper .panel-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.settings-form, .page-info-form--settings, .page-roles-form{
    position: relative;
    width: 100%;
    float: left;
    padding: 20px 0;
}
.page-info-form--settings .form-group, .settings-form .form-group,  .page-roles-form .form-group{
    margin-bottom: 0px !important;
}
.settings-form .settings-label, .page-info-form--settings .info-label, .page-roles-form .role-label{
    font-size: 12px;
    font-weight: bold;
}
.settings-form .radio{
    margin-top: 0px;
}
.settings-form .radio label{
    font-size: 12px;
}
.settings-form .radio-header input{
    position: relative;
    top:2px;
}
.row-inner{
    position: relative;
    width: 96%;
    left:2%;
    padding: 20px 0;
    float: left;
    border-bottom: solid 1px #DADADA;
}
.row-inner [class*='col-']{
    padding-left:0;
    padding-right: 0;
}
.row-inner .px-15{
    padding-left: 15px;
}
.pt-15{
    padding-top:15px !important;
}
.p-15{
    padding: 15px;
}
.row-inner .mx-15{
    margin-left: 15px;
}
.bg-white{
    background-color: #fff;
}
.page-img-wrap{
    position: relative;
    width: 40px;
    height: 40px;
    float: left;
}
.page-name-wrap{
    position: relative;
    width: 80%;
    margin-left:10px;
    float: left;
}
.w-45{
    width: 45%;
    margin-right: 15px;
    float: left;
}
.w-60{
    width: 60%;
}
.page-name-wrap .user-name{
    font-weight: 600;
}
.page-name-wrap .user-role{
    opacity: .5;
}
.add-new-outer{
    display: flex;
    justify-content: flex-end;
}
.service-card{
    background: #FBFBFB;
    border: 1px solid #E9E9E9;
    border-radius: 2px;
    margin:10px 0;
    width: 100%;
    padding:30px;
}
.service-card .service-name{
    font-size: 13px;
    font-weight: 600;
    color: #000;
    margin-bottom: 6px;
}
.service-card .duration{
    font-size: 12px;
    color: #7E7E7E;
    margin-bottom: 6px;
}
.service-card p{
    font-size: 10px;
    line-height: 16px;
    color: #000000;
    word-wrap: break-word;
}
.service-card--img{
    position: relative;
    width: 100%;
    height: 158px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #ccc;
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.edit-del a{
    font-size: 12px;
}
.border-bottom-none{
    border-bottom: none !important;
}
.see-all{
    position: relative;
    float: right;
    right: 15px;

}
.two-col-layout{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.business-overview-img{
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.assign-role .selectize-input {
    min-width: 100%;
}
#friend_circle_members{
    padding:7px;
    border-radius: 4px;
}
.flex-wrap{
    flex-wrap: wrap;
}
.no-results{
    position: relative;
    width: 100%;
    float: left;
}
.add-service{
    margin-left:0px !important;
    width: 100% !important;
}
@media( max-width:991.98px){
    .mb-sm-4{
        margin-bottom: 15px;
        padding: 0 !important;
    }
}
@media( max-width:767.98px){
    .follow-links .btn-orange-medium{
        padding: 6px 12px !important;
        font-size: 14px;
    }
    .pb-4{
        padding-bottom: 20px;
        padding-left:0;
        padding-right:0;
    }
    .side-right.follow-links{
        margin-top:10px;
    }
}
@media( max-width:564.98px){
    .page-name-wrap{
        width: 75%;
        margin-left: 0px;
    }
    .follow-links .pt-15{
        padding-top:0px !important;
    }

}

.album-section-head{
    padding:12px 15px;
    border-bottom: 1px solid #e9e9e9;
}
.album-section-head h3{
    margin: 0;
    font-size: 15px;
    font-weight: bold;
}
.album-section-head .btn-danger{
    font-size: 14px;
}
.panel-albums{
}
.panel-albums__heading{
    padding:15px;
    text-align:right;
    border: 0 !important;
    background: none !important;
}
.panel-albums__heading .btn-orange-medium{
    font-size:14px !important;
}
.panel-albums__content{
    width:100%;
    float:left;
    padding:15px;
}
.panel-albums__widget-card{
    padding: 0;
    width: 100%;
    float: left;
    margin: 0 0 20px;
}
.panel-albums__widget-card-img{
    width:100%;
    height:150px;
    float:left;
    position:relative;
}
.panel-albums__widget-card-img a{
    width:100%;
    height:100%;
    float:left;
    position:relative;
    z-index:10;
}
.panel-albums__widget-card-img.album-frame:before{
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    border-radius:1px;
    border:1px solid #ddd;
    left:4px;
    top:4px;
    background-color:#fff;
}
.panel-albums__widget-card-img.album-frame:after{
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    border-radius:1px;
    border:1px solid #ddd;
    left:2px;
    top:2px;
    background-color:#fff;
}
.panel-albums__widget-card-img img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}
.panel-albums__widget-card-data{
    width:100%;
    float:left;
    margin:10px 0 0;
}
.panel-albums__widget-card-data a{
    font-size:12px;
    font-weight:600;
}
.widget-card-data__photo-count{
    font-size:11px;
    color:#888;
}
.album-widget-checkbox .page-album-checkbox{
    width:20px;
    height:20px;
}
.text-lower{
    text-transform: none !important;
}
.jscroll-inner{
    width: 100%;
    float: left;
}
#link_other{
	position:relative;
	width:100%;
}



/* Social Icons */


#yudu-brand .white-box{
    background:#fff;
    -webkit-border-radius:10px;
    border-radius:10px;
    -webkit-box-shadow:0px 15px 30px 0px rgba(0,0,0,0.1);
    box-shadow:0px 15px 30px 0px rgba(0,0,0,0.1);
    max-width:800px;
    margin:0px auto;
}

#yudu-brand #social-icon input,
#yudu-brand #social-icon textarea,
#yudu-brand #social-icon select{
    outline:none !important;
}

#yudu-brand #social-icon p{
    font-size:14px;
}

#yudu-brand #social-icon h2{
    font-size:20px;
    padding-top:10px;
    font-weight:700;
    margin-bottom:10px;
}

#yudu-brand #social-icon hr{
    border:none;
    border-bottom:2px solid #eee;
}

#yudu-brand #social-icon .inner{
    padding:20px;
}


#yudu-brand #social-icon .fields{
    padding:10px;
    overflow: hidden;
}


#yudu-brand #social-icon .fields .field-col{
    width:30.33%;
    float:left;
    padding:1.5%;
}

#yudu-brand #social-icon .fields .field-col.field-col-two{
    width: 60.66%;
    display: none;
}

#yudu-brand #social-icon .fields .field-col.padding-top{
    padding-top: 4%;
    padding-bottom: 3%;
}

#yudu-brand #social-icon .fields .field-col label{
    display: block;
    font-size:13px;
    margin:0px;
    margin-bottom:5px;
}


#yudu-brand #social-icon .fields .field-col input[type=text]{
    border:none;
    background:#F6F6F6;
    padding:10px;
    display: block;
    width:100%;
    /* height:36px; */
}

#yudu-brand #social-icon .fields .field-col select{
    border:none;
    background:#F6F6F6;
    padding:10px;
    display: block;
    width:100%;
    height:36px;
}

#yudu-brand #social-icon #social-icon-code{
    overflow: hidden;
    padding:24px;
}

#yudu-brand #social-icon .code-container{
    width:80%;
    float:left;
}

#yudu-brand #social-icon .code-container textarea#social-icon-html-code{
    width:100%;
    border:none;
    background:#F6F6F6;
    padding:10px;
    box-sizing:border-box;
    display: block;
    height:100px;
    font-size:12px;
}

#yudu-brand #social-icon .code-container label{
    display: block;
    font-size:13px;
    margin:0px;
    height:auto;
    margin-bottom:15px;
}

#yudu-brand #social-icon .preview-container{
    float:left;
    width:20%;
}

#yudu-brand #social-icon span.preview{
    display: block;
    font-size:10px;
    color:#888;
    margin:0px;
    margin-bottom:15px;
    text-align: center;
    height:20px;
}

#yudu-brand #social-icon #icon-preview{
    width:100px;
    height:100px;
    display: block;
    background:#F6F6F6;
    margin:0px auto;
    position: relative;
}

#yudu-brand #social-icon #icon-preview.w{
    background:#FF6C00;
}

#yudu-brand #social-icon #icon-preview img{
    position: absolute;
    top:50%;
    left:50%;
    /* width:40px; */
    /* height:40px; */
    /* background:#eee; */
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
}

#copy-code{
    background: none;
    border: 1px solid #ff6c00;
    padding: 4px 10px;
    color: #ff6c00;
    font-size: 10px;
    font-weight: 500;
    margin-left: 6px;
    border-radius: 19px;
}

@media screen and (max-width:992px){
    #yudu-brand{
        padding:30px 20px;
    }

    #yudu-brand #social-icon h2{
        font-size:18px;
        text-align: center;
    }
    #yudu-brand #social-icon p{
        text-align: center;
    }

    #copy-code{
        display: block;
        margin: 10px auto;
    }

    #yudu-brand #social-icon .fields .field-col,
    #yudu-brand #social-icon .fields .field-col.field-col-two{
        width:97%;
        padding-bottom:20px;
    }

    #yudu-brand #social-icon .code-container label{
        float: left;
        width: 100%;
        padding-bottom: 20px;
    }

    #yudu-brand #social-icon .code-container{
        width:100%;
        text-align: center;
    }

    #yudu-brand #social-icon .preview-container{
        width:100%;
        padding-top:20px;
    }

    #yudu-brand #social-icon .code-container textarea#social-icon-html-code{
        text-align: center;
        font-size:11px;
        min-height:75px;
    }
}

.widget-card-overlay {
    position: relative;
}
.play-pause-btn-wrap {
    float: right;
    position: relative;
    bottom: 6px;
    right: 0;
}
.play-pause-btn {
    float: right;
    width: 24px;
    height: 24px;
    padding: 0px;
}
.play-pause-img {
    width: 24px;
    height: 24px;
    border-radius: 50%
}

a.adcenter-video:hover {
    opacity: 1 !important;
}