.create-body 
{
	.create-list-group
	{
		.list-group-item 
		{
			border-color: #E6EAEE!important;
			&:last-child 
			{
				border-bottom: 0px;
				padding-bottom: 0px;
			}
		}
		.form-control 
		{
			float: none!important;
			width: 100%!important;
			border-color: #E6EAEE!important;
		}
		.create-form 
		{
			padding-right: 0px;
			.no-bg 
			{
				background: #fff!important;
			}
		}
	}
	.side-label
	{
		line-height: 25px;
		font-size: 14px;
	}
	.btn-success-create
	{
		padding: 7px 20px;
	}
	.line-separator 
	{
		border-bottom: 1px solid #E6EAEE;
	}
	.select-option 
	{  
		.radio-header
		{
			color: #354052;
			font-size: 14px;
			text-transform: none;
		}
		i 
		{
			margin-right: 5px;
			color: #7F8FA4;
		}
		.radio-text 
		{
			font-size: 14px;
			color: #7F8FA4;
			text-transform: none;
			margin-bottom: 0px;
			margin-left: 15px;
		}
	}
}
.right-panel
{
	.privacy-question 
	{
		padding-bottom: 0px;
		.right-list-group 
		{
			margin-bottom: 10px;
			.list-group-item 
			{
				.holder 
				{
					padding-left: 0px;
				}
				&:first-child
				{
					padding-top: 7px;
				}
				&:last-child 
				{
					padding-bottom: 0px;
				}
				.right-side-label
				{
					font-size: 14px;
					text-transform: none;
					color: #354052;
					font-weight: 400;
				}

				.page-description 
				{
					color: #7F8FA4;
					font-size: 12px;
					margin-top: 10px;
				}
			}
		}
	}
	
}
//user-timeline 
.user-bio-block 
{
	background: #FFFFFF;
	margin-top: 20px;
	margin-bottom: 20px;
	border: 1px solid #E6EAEE;
	border-radius: 4px 4px 0px 0px;
	padding: 18px 21px;
	font-size: 14px;
	.bio-header 
	{
		color: #354052;
		font-weight: 600;
	}
	.privacy-type 
	{
		font-weight: 600;
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #E6EAEE;
		.fa 
		{
			color: #354052;
			margin-right: 5px;
			margin-left: 5px;
		}
	}
	.bio-description 
	{
		padding: 13px 0px;
		color: #5B6B81;
	}
	.bio-list 
	{
		margin-bottom: 0px;
		li 
		{
			margin-bottom: 7px;
			&:last-child 
			{
				margin-bottom: 0px;
			}
			i 
			{
				font-size: 16px;
				color: #BBC5D5;
				width: 20px;
				margin-right: 5px;
			}
			span 
			{
				color: #354052;
				font-weight: 600;
			}
		}
	}
}

//my photos block
.widget-best-pictures 
{
	background: #FFFFFF;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 0px;
	border-radius: 4px;
	border: 1px solid #E6EAEE;
	.picture
	{
		padding-bottom: 0px;
		text-transform: capitalize;
	}
	.pull-right.show-all
	{
		padding-right: 17px; 
		a 
		{
			color: #00AEEF;
		}
	}
	.my-best-pictures 
	{
		padding: 15px 15px 10px 15px;
		.best-pics 
		{
			padding-left: 5px;
			padding-right: 5px;
			margin-bottom: 10px;
			a 
			{
				display: inline-block;
			}
		}
	}
}

//admin-section
.make-announcements
{
	background: #FFFFFF;
	border: 1px solid #E6EAEE;
	border-radius: 4px;
	padding: 25px 20px 10px 20px;
	.form-group 
	{
		margin-bottom: 0px;
		.announcement-header
		{
			
			font-size: 14px;
			margin-bottom: 12px;
		}
		.wrap 
		{
			background: #FFFFFF;
			border-radius: 4px;
		}
	}
	.add 
	{
		margin-top: 10px;
		.btn-add 
		{
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border: 1px solid #1B92EC;
			border-radius: 4px;
			padding: 5px 16px;
		}
	}
}

.smiley-list 
{
	padding: 0px;
	display: flex;
	justify-content:space-between;
	border-bottom: 1px solid #E6EAEE;

	li 
	{
		margin-left: 0px;
		a 
		{
			font-size: 14px;
			margin-left: 0px;
			border-bottom: 2px solid transparent;
			color:#354052;
			border-radius: 0px;
			padding-bottom: 5px;
			padding-left: 0px;
			padding-right: 0px;
			.fa 
			{
				color:#354052;
				border-right: 0px;
			}
		}
	}
	li.active 
	{
		border-bottom: 2px solid #2EA2F8;
		a 
		{
			color:#354052!important;
			}
	}
}
.smiley-pics-content
{
	border:0px;
	padding: 5px 0px 5px 15px;
	margin-bottom: 0px;
	max-height: 120px;
	overflow:auto;
	.smiley-post 
	{
		padding-right: 3px;
		margin-top: 7px;
		display: inline-block;
	}
}

// modal when clicked on like symbol
.modal-likes 
{
	width: 500px;
	.people-like-list
	{
		margin-bottom: 0px;
		border-radius: 0px 0px 4px 4px;
		height: 360px;
		overflow-x: hidden;
		overflow-y: scroll;
		.list-group-item
		{
			border-radius: 0px;
			border:1px solid #E6EAEE;
			&:first-child
			{
				border-top: 0px;
			}
			&:last-child 
			{
				border-radius: 0px 0px 4px 4px;
			}
			.media 
			{
				img 
				{
					width: 40px;
					height: 40px;
					border-radius: 4px;
				}
				.media-body 
				{
					.media-heading 
					{
						.user 
						{
							text-decoration: none;
							color: #354052;
						}
					}
					.username   
					{
						text-decoration: none;
						color: #7F8FA4;
					}
					.media-heading 
					{
						margin-bottom: 0px;
						line-height: 20px!important;
						.follow-user 
						{
							padding: 6px 30px;
							margin-top: 3px;
						}
						.following-user
						{
							padding: 6px 20px;
						}
					}
				}
			}
		}
	}
}

//modal when clicked on create album
.album-modal 
{
	width: 500px;
	.modal-dialog
	{
		margin: 30px auto;

	}
	.album-header 
	{
		border-bottom: 0px;
		.modal-title
		{
			font-size: 18px;
			font-weight: 600;
		}
	}
	.album-body
	{
		padding: 30px 16px;
		.albums-list 
		{
			margin-top: 12px;
			padding-left: 8px;
			padding-right: 8px;
			overflow-x: hidden;
		    overflow-y: scroll;

			.album-images:hover,.album-images.active
			{
				.album 
				{
					border-radius: 4px;
					img 
					{
						opacity: 0.7;
					}
				}
			}
			.album-images.active
			{
				.btn-remove
				{
					display: block;
				}
			}
			.album-images 
			{
				margin-bottom: 14px;
				padding-left: 7px;
				padding-right: 7px;
				.album 
				{
					background-color: #FFFFFF;
					border-radius: 4px;
					width: 100%;
					height: 90px;
					overflow: hidden;
					 img 
					{
						width: 100%;
						height: auto;
						border-radius: 4px;
					}
				}
				.btn-remove 
				{
					position: absolute;
					display: none;
					right: 10px;
					width: 16px;
					height: 16px;
					top: 2px;
					opacity: 0.7;
					padding: 0px;
					text-align: center;
					border-radius: 50%;
					border:1px solid #000000;
					background: #000000;
					line-height: 12px;
					i 
					{
						color: #E76877;
						font-size: 12px;
					}
				}
			}
		}
		.album-box 
		{
			border:1px solid transparent!important;
			padding: 0px;
			height: 110px!important;
			.inner-box 
			{
				background: #F7F8FA;
				padding: 10px;
				border:2px dashed #C4CDD9!important;
				.inner-box-header
				{
					font-size: 16px;
					color: #354052;
					margin:0px;
				}
				i 
				{
					margin-top: 0px!important;
					font-size: 36px;
					color: #1B92EC;
				}
			}
		}
		.album-buttons 
		{
			margin-top: 15px;
			.btn-danger 
			{
				padding: 7px 22px;
				background: #E76877;
				border-radius: 4px;
			}
			.btn-default 
			{
				padding: 6px;
			}
			.btn-success 
			{
				padding: 7px 30px;
			}
			.right-buttons 
			{
				a 
				{
					margin-left: 12px;
				}
			}
		}
	}
}
// themes page 
.dark-well 
{
	background: #273142;
	border: 1px solid #E6EAEE;
	padding: 11px 11px 11px 77px;
	border-radius: 4px 4px 0px 0px;
	p 
	{
		color: #FFFFFF;
		margin: 0px;
	}
}
.independent-themes
{
	.independent-block
	{
		background: #FFFFFF;
		border: 1px solid #E6EAEE;
		border-radius: 4px;
		padding:10px;
		margin-bottom: 20px;
		.theme-image 
		{
			a img 
			{
				width: 100%;
				height: 160px;
			}
			.selected 
			{
				display: none;
				text-transform: none;
				position: absolute;
				font-weight: 400;
				right:36px;
				top:20px;
				font-size: 12px;

				background: rgba(0, 0, 0, 0.59);
				border:1px solid transparent;
				color: #FFFFFF;
				padding: 5px 17px;
			}
		}
		

		.theme-sale 
		{
			margin-top: 15px;
			margin-bottom: 5px;
			.theme-details 
			{
				margin-left: 10px;
				.theme-name 
				{
					color: #354052;
					font-size: 14px;
				}
				.theme-author 
				{
					color: #7F8FA4;
					font-size: 12px;
				}
			}
			.theme-rate
			{
				background: #E8EAF1;
				border:1px solid #E8EAF1;
				border-radius: 4px;
				font-size: 14px;
				color: #8D909F;
				height: 36px;
				width: 36px;
				text-align: center;
				line-height: 36px;
			}
		}
	}
	.independent-block:hover,.independent-block.active
	{
		background: #222C3C;
		border: 1px solid #E6EAEE;
		border-radius: 4px;
		padding:10px;
		margin-bottom: 20px;
		.theme-sale .theme-details 
		{
			.theme-name 
			{
				color: #FFFFFF;
			}
		}
		.theme-rate
		{
			background: rgba(25, 145, 235, 0.4);
			border: 1px solid #1991EB;
			border-radius: 4px;
			color: #FFFFFF;
		}
	}
	.independent-block.active
	{
		.selected
		{
			display: block;
		}
	}
}
// contact form 
.login-block.static-pages
{
	width: 100%;
	padding-top: 0px;
}
.panel-body.static-body 
{
	padding: 0px 30px 30px 30px;
}
.static-para
{
	margin-bottom: 0px;
	padding: 25px 0px 42px 0px;
}
.contact-panel 
{

	.contact-login
	{
		padding: 0px 15px;
		
		margin-top: 0px;
		
		.form-group 
		{
			margin:11px 0px;
			.form-control 
			{
				padding: 13px;
			}
		}
		.btn-submit 
		{
			margin: 0;
		    padding: 8px;
		    border-radius: 2px;
		    min-width: 142px;
		    outline: none;
		}
	}
}










