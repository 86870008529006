@import 'variables.less';

// ****************************************************************************
// *                                TYPOGRAPHY                                *
// *                            includes h tags, p                            *
// ****************************************************************************

body
{
    background: #fff;
    font-family: @lato;
    font-size: @primary-font-size;
    color: @primary-content-color;
	letter-spacing: 0.1px;

}

h1
{
	font-size: 32px;
	line-height: 1.5;	
}
h2
{
	font-size: 22px;
	line-height: 1;	
}
h3
{
	font-size: 20px;
	line-height:  1.4;	
}
h4
{
	font-size: 16px;
	line-height:  1.4;	
}
p
{
	font-family: @primary-font;
	font-size: 14px;
	color: #71787E;
	line-height: 24px;
}

.striked
{
	text-decoration: line-through;
}

.no-margin
{
	margin: 0px !important;
}

a
{
	color: @primary-color;
}
a:hover, a:focus, a:active{
	color: @primary-color;
	opacity:.8;
}