.stripe-style {
  padding:15px 0px;
}

.stripe-style * {
  font-family: "Open Sans", Segoe UI, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.stripe-style form {
  max-width: 496px !important;
  padding: 0 15px;
}

.stripe-style form > * + * {
  margin-top: 20px;
}

.stripe-style .container {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 3px;
}

.stripe-style fieldset {
  border-style: none;
  padding: 5px;
  margin-left: -5px;
  margin-right: -5px;
  background: rgba(18, 91, 152, 0.05);
  border-radius: 8px;
}

.stripe-style fieldset legend {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #8898aa;
  padding: 3px 10px 7px;
}

.stripe-style .card-only {
  display: block;
}
.stripe-style .payment-request-available {
  display: none;
}

.stripe-style fieldset legend + * {
  clear: both;
}

.stripe-style input, .stripe-style button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  color: #fff;
}

.stripe-style input:-webkit-autofill {
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
}

.stripe-style #card-element {
  padding: 10px;
  margin-bottom: 2px;
}
.stripe-style #card-errors{
  font-size: 12px !important;
  color: #e25950 !important;
  margin-bottom: 4px !important;
}
body .stripe-style input {
  -webkit-animation: 1ms void-animation-out;
  color: #354052 !important;
  font-weight: 400 !important;
  border-bottom: solid 1px #ccc;
  padding-bottom: 5px;

  width: 92%;
  background-color: transparent !important;
  font-size: 14px !important;
}

.stripe-style input::-webkit-input-placeholder {
  color: #354052;
}

.stripe-style input::-moz-placeholder {
  color: #354052;
}

.stripe-style input:-ms-input-placeholder {
  color: #354052;
}

.stripe-style button {
  display: block;
  width: 100%;
  height: 37px;
  background-color: #000;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.stripe-style button:active {
  background-color: #000;
}

.stripe-style .error svg .base {
  fill: #e25950;
}

.stripe-style .error svg .glyph {
  fill: #f6f9fc;
}

.stripe-style .error .message {
  color: #e25950;
}

.stripe-style .success .icon .border {
  stroke: #ffc7ee;
}

.stripe-style .success .icon .checkmark {
  stroke: #d782d9;
}

.stripe-style .success .title {
  color: #32325d;
}

.stripe-style .success .message {
  color: #8898aa;
}

.stripe-style .success .reset path {
  fill: #d782d9;
}