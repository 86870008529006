@import 'variables.less';

// ****************************************************************************
// *                               MISCELLANEOUS                              *
// *                         includes all extra styles                        *
// ****************************************************************************
.img-icon
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.list-group-navigation
{
	.list-group-item
	{
		.list-icon
		{
			width: 40px;
			height: 40px;
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border-radius: 4px;
			font-size: 16px;
			color: white;
			text-align: center;
			line-height: 40px;
			float: left;
		}
		.list-text
		{
			margin-left: 60px;
			color: @black;
			font-weight: 600;
			text-transform: capitalize;
			.text-muted
			{
				font-weight: 400;
			}
		}
		
		border: 1px solid @panel-border-color;
		&:hover
		{
			background-color: @default-from-color;
		}
		
	}
}

.mt-1 {
	margin-top: 1rem !important;
}
.mt-2 {
	margin-top: 2rem !important;
}
.mt-3 {
	margin-top: 3rem !important;
}
.mt-4 {
	margin-top: 4rem !important;
}
.mt-12 {
	margin-top: 12rem !important;
}

.mb-1 {
	margin-bottom: 1rem !important;
}
.mb-2 {
	margin-bottom: 2rem !important;
}
.mb-3 {
	margin-bottom: 3rem !important;
}
.mb-4 {
	margin-bottom: 4rem !important;
}

.ml-1 {
	margin-left: 1rem !important;
}
.ml-2 {
	margin-left: 2rem !important;
}
.ml-3 {
	margin-left: 3rem !important;
}
.ml-4 {
	margin-left: 4rem !important;
}

.mr-1 {
	margin-right: 1rem !important;
}
.mr-2 {
	margin-right: 2rem !important;
}
.mr-3 {
	margin-right: 3rem !important;
}
.mr-4 {
	margin-right: 4rem !important;
}

.ml-auto {
	margin-left: auto;
}
.mr-auto {
	margin-right: auto;
}

.pt-1 {
	padding-top: 1rem !important;
}
.pt-2 {
	padding-top: 2rem !important;
}
.pt-3 {
	padding-top: 3rem !important;
}
.pt-4 {
	padding-top: 4rem !important;
}

.pb-1 {
	padding-bottom: 1rem !important;
}
.pb-2 {
	padding-bottom: 2rem !important;
}
.pb-3 {
	padding-bottom: 3rem !important;
}
.pb-4 {
	padding-bottom: 4rem !important;
}

.pl-1 {
	padding-left: 1rem !important;
}
.pl-2 {
	padding-left: 2rem !important;
}
.pl-3 {
	padding-left: 3rem !important;
}
.pl-4 {
	padding-left: 4rem !important;
}

.pr-1 {
	padding-right: 1rem !important;
}
.pr-2 {
	padding-right: 2rem !important;
}
.pr-3 {
	padding-right: 3rem !important;
}
.pr-4 {
	padding-right: 4rem !important;
}

.rounded {
	border-radius: .5rem !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}