.c-fa {
    color: #fff !important;
}

/******** Header Section ********************/
.primary-bg {
    background-color: @primary-color;
    height: 40px;
}

.yudu-navbar {
    // background-color: rgb(248, 248, 248) !important;
    background-image: linear-gradient(
    rgba(248, 248, 248, 1) 49%,rgba(248, 248, 248, .7) 71%, rgba(248, 248, 248, 0) 100%
    ) !important;

    background-image: -moz-linear-gradient(
    rgba(248, 248, 248, 1) 49%,rgba(248, 248, 248, .7) 71%, rgba(248, 248, 248, 0) 100%
    ) !important;

    height: 235px;

    .navbar-brand {
        margin-left: 2rem;
    }

    .yudu-nav-list {
        ul {
            position: relative;
            left: 15%;
            top: 1.4rem;

            li {
                font-weight: bold;
                padding: 0px 4rem;
            }
        }
    }

    .yudu-page-search {
        float: right;
        margin-top: 2.4rem;
        margin-right: 4rem;

        input {
            border-color: @primary-color;
            border-style: solid;
        }

        ::placeholder {
            color: @primary-color;
        }

        .yudu-search-icon {
            position: relative;
            right: 3rem;
            color: @primary-color;
        }
    }
}

.yudu-header-container {
    width: 100%;
}

.yudu-right-nav {
    margin-right: 4rem;
    margin-top: 0px !important;
}

.navbar-custom .navbar-right {

    .navbar-btn-join, .navbar-btn-login {
        color: #fff;
        font-family: @lato;
        font-weight: bold;
        text-align: center;
        margin-top: .2rem;
    }

    .navbar-btn-join {
        height: 25px;
        border: 3px solid #fff;
        margin-top: .7rem;
        a {
            color: #fff;
            font-family: @lato;
            font-weight: bold;
            text-align: center;
            position: relative;
            top: -.8rem;
        }
    }
}

.small-font {
    font-size: 12px !important;
}

.navbar-fixed-top {
    position: relative !important;
    margin-bottom: 45px;
}

.navbar-custom .yudu-social-header .navbar-brand {
    margin-top: 0px;
    margin-bottom: 0px;
}

.navbar-custom .yudu-social-header .navbar-right {
    margin-top: 15px !important;
    margin-right: 0px;
    margin-bottom: 0;
}

.navbar-brand {
    width: 120px;
}
.album-embed-responsive .album-embed-responsive-item{
    max-height:none !important;
}

.navbar-brand > img {
    max-width: 100%;
}
.auto-height-comment{
        padding-right: 12px !important;
        line-height:1.4 !important;
        overflow:hidden;
}
.yudu-social-header.navbar-default .navbar-nav > li {
    padding: 0;
    position: relative;
    height: 30px;
    margin-left: 0px;
}

.second-nav li {
    width: 19% !important;
}

// .yudu-social-header.navbar-default .navbar-nav > li:before {
//     position: absolute;
//     content: '';
//     top: 50%;
//     margin-top: -10px;
//     left: 0px;
//     width: 1px;
//     height: 22px;
//     background-color: #5e5e5e;
//     float: left;
// }

.yudu-social-header.navbar-default .navbar-nav > li:first-child::before {
    display: none;
}

.yudu-social-header.navbar-default .navbar-nav > li:first-child {
    border-left: 0;
    position: relative;
}

.yudu-social-header.navbar-default .navbar-nav > li > a {
    color: #fff !important;
    padding: 4px 22px;
    font-size: 16px;
    font-weight: 600;
    outline: none;
}

.yudu-social-header.navbar-default .navbar-nav > li > a:hover {
    opacity: .8;
}

.yudu-social-header.navbar-default .navbar-nav > li > a .fa {
    /* margin-right: 8px; */
    color: #fff;
    font-size: 20px;
    position: relative;
    top: 0px;
}

.register-login li:before {
    top: 35% !important;
    margin-top: 0px !important;
}

.register-login.navbar-right {
    margin-top: 13px !important;
}

.friend-request-wrap {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.friend-request-wrap .req-text {
    font-size: 15px;
}

.request-left {
    position: relative;
    width: 45%;
    float: left;
}

.request-left .media-left .img-icon {
    width: 40px !important;
    height: 40px !important;
}

.request-right {
    position: relative;
    width: 55%;
    padding-top: 8px;
}

.small-btn {
    padding: 4px 7px !important;
}

.interest-wrap {
    width: 90%;
    left: 5%;
}

.album-btn-wrap {
    margin-bottom: 30px;
}

.custom-label-album {
    font-size: 12px !important;
    font-weight: 400 !important;
    position: relative;
    top: -6px;
}

.album-widget-checkbox {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}

.album-select {
    height: 36px;
    padding: 0px 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.btn-album-sm {
    font-size: 11px !important;
    margin-top: 10px !important;
}

.album-checkbox {
    margin-left: 10px !important;
}

.interest-head {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    text-align: center;
    border-bottom: 1px solid #E6EAEE;
    border-radius: 0px;
    margin-bottom: 0px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 17px;
    width: 90%;
    line-height: 1.4;
    left: 5%;
    float: left;
}

.interest-wrap .panel-default {
    background: transparent;
    border: none;
    border-color: transparent;
    margin-top: 0px !important;
}

.interest-wrap .panel-default .panel-heading {
    background-image: none;
    border: none;
    background-color: transparent;
    padding: 10px 15px 10px 0px;
    border-bottom: solid 1px #E6EAEE;
    cursor: pointer;
}

.interest-wrap .panel-heading .panel-title a {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    width: 100%;
}

.interest-wrap .panel-heading .panel-title a i {}

.interest-wrap .panel-heading .panel-title a:hover,
.interest-wrap .panel-heading .panel-title[aria-expanded="true"] a {
    color: #ff6c00;
}

.interest-wrap .panel-heading .panel-title a input[type="checkbox"] {
    position: relative;
    margin-right: 5px;
}

.interest-wrap .panel-heading .panel-title a i {
    position: relative;
    color: #000;
    float: right;
    font-size: 17px;
    top: 5px;
}

.interest-elem {
    margin-bottom: 6px;
}

.interest-wrap .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 0px solid transparent;
}

.interest-wrap .panel-heading {
    padding: 5px 15px;
}

.login-outer {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 80px;
    position: relative;
    float: left;

    #bgVid {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
    }

    .login-block {
        position: relative !important;
        padding-top: 250px;
    }
    // background: #000 url(../img/mountain_cinemegraph.mov) no-repeat top center;
    // background-size: cover;
}

.privacy-question {
    padding-left: 16px;
    padding-right: 20px;
    padding-bottom: 20px;
    .list-group {
        .list-group-item {
            padding: 10px 0px;
            border-left: transparent;
            border-right: transparent;
            border-radius: 0px;
            .form-group {
                width: 100%;
                label {
                    font-family: @primary-font;
                    font-size: @primary-font-size;
                    color: #7F8FA4;
                    font-weight: 400;
                    text-transform: none;
                    margin-bottom: 0px;
                    padding-top: 8px;
                }
                .form-control {
                    border: 1px solid #CED0DA;
                    border-radius: 4px;
                    float: right;
                    width: 130px;
                }
            }
        }
        .list-group-item:first-child {
            border-top: transparent;
            padding-top: 16px;
        }
    }
    .btn-success {
        padding: 7px 12.5px;
    }
}

.footer-description {
    .socialite-terms {
        padding-bottom: 10px;
        font-size: @primary-font-size;
        font-family: @primary-font;
        font-weight: 400;
        color: #fff;
        @media (max-width: 440px) {
            font-size: 11px;
        }
        a {
            font-family: @primary-font;
            font-size: @primary-font-size;
            color: #249AF2;
            text-transform: capitalize;
            font-weight: 400;
            vertical-align: middle;
            @media (max-width: 440px) {
                font-size: 11px;
            }
        }
    }
    .socialite-terms:nth-child(1) {}
}

.list-group.socialite-group {
    margin-bottom: 20px;
    .list-group-item {
        padding: 6px 15px !important;
        .badge {
            margin-top: 9px;
        }
        &:hover {
            .socialite-icon {
                background: #ff6c00;
                i {
                    color: #FFFFFF;
                }
            }
        }
    }
    .list-icon.socialite-icon {
        background: #9d9d9d;
        i {
            color: #fff;
        }
        &:hover {
            background: #ff6c00;
            i {
                color: #ffffff;
            }
        }
    }
    .list-icon.active {
        background: #ff6c00;
        i {
            color: #ffffff;
        }
    }
    .list-icon.disabled
	{
        background: #9d9d9d;
		i
		{
			color: #ffffff;
		}
	}

}

.admin-logo {
    position: relative;
    padding: 6px;
    background-color: #ff6c00;
}

a.text-primary,
.text-success {
    color: #ff6c00 !important;
}

a.text-primary:hover {
    color: #ff6c00;
}

.widget-sales a.text-primary,
.widget-sales .text-success {
    cursor: default;
}

.widget-sales .text-success:hover {
    color: #ff6c00;
}

.manage-user-mt {
    margin-top: 30px;
}

.custom-margin-bottm {
    margin-bottom: 30px;
}

//general-settings
.panel-heading.panel-settings {
    padding-top: 24px;
    padding-left: 27px;
    .panel-title {
        font-family: @primary-font;
        font-size: 24px;
        font-weight: 400;
    }
}

.socialite-form {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    padding-top: 29px;
    .form-control {
        padding-right: 35px !important;
    }
    .help-block {
        padding: 10px 0px 0px 0px;
    }
    .avatar-details {
        .avatar-pic {
            display: inline-block;
            padding-left: 17px;
            .avatar-name {
                font-size: @primary-font-size;
                color: @primary-content-color;
                text-transform: capitalize;
                font-weight: 600;
                margin: 0px;
            }
            .avatar-size {
                font-size: 12px;
                color: #7F8FA4;
                font-weight: 400;
                margin: 0px;
            }
        }
    }
    .form-group {
        .input-group.gender-select {
            width: 100%;
            .form-control {
                font-family: @primary-font;
                font-size: @primary-font-size;
                color: @primary-content-color;
                border-radius: 4px;
            }
        }
        .input-group.datepicker {
            padding: 0px;
            .calendar-addon {
                color: #C5D0DE;
                font-size: 16px;
            }
            .form-control {
                background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
                border-right: none;
                border-left: none;
            }
        }
    }
}

.share-option-select {
    width: 100%;
    height: 46px;
    border-radius: 3px;
    color: #666;
    padding: 5px;
}

.share-option-label {
    width: 100%;
    float: left;
    margin-bottom: 6px;
    border-right: none !important;
}

.share-option-wrap .form-group {
    padding-left: 0px !important;
}

.share-option-wrap .form-group .selectize-control .selectize-input {
    height: 46px;
    border-radius: 3px;
    color: #666;
    padding: 13px 0px 0px 5px;
    border: solid 1px rgb(169, 169, 169);
}

.share-text-area {
    margin-bottom: 9px;
}

.padding-bottom-eraser {
    padding-bottom: 0px !important;
}

.post-share-body {
    border-top-width: 1px !important;
    background-color: #fff;
}

.socialite-security {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 28px;
    .btn-save-change {
        padding-top: 22px;
    }
}

//affiliates
.affiliate-settings {
    .affiliate-link {
        text-align: center;
        padding-bottom: 20px;
        .link-heading {
            font-family: @source-sans;
            font-size: @primary-font;
            color: @primary-content-color;
            font-weight: 400;
            margin: 0px;
            padding-bottom: 7px;
        }
        a {
            font-family: @source-sans;
            font-size: @primary-font;
            color: #789A6A;
            font-weight: 400;
        }
        .affiliate-buttons {
            padding-top: 8px;
            .btn-default {
                padding: 6px 15px;
                color: @primary-content-color;
                margin-right: 17px;
                i {
                    color: #3B5998;
                    font-size: 20px;
                    padding-right: 9px;
                }
            }
            .btn-default.btn-twitter {
                padding: 6px 25px;
                i {
                    color: #5EA9DD;
                    font-size: 20px;
                }
            }
        }
    }
    .affliate-followers {
        .list-group-item {
            border-left: none;
            border-right: none;
            border-radius: 0px;
            padding: 15px 0px;
            .connect-link {
                a {
                    font-family: @primary-font;
                    font-size: @primary-font-size;
                    color: @primary-content-color;
                    font-weight: 400;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 0px;
                    }
                    .name {
                        padding-left: 20px;
                    }
                }
            }
            .connect-mail {
                .btn-default {
                    padding: 7px 17px;
                    i {
                        color: #1B92EC;
                        font-size: 14px;
                        padding-right: 9px;
                    }
                }
            }
        }
    }
}

//connected-services
.startpeep-services {
    padding-left: 13px;
    padding-right: 13px;
    .list-group-item {
        border-left: none;
        border-right: none;
        border-radius: 0px;
        padding: 15px 0px;
        .connect-list {
            .connect-link {
                a {
                    font-family: @primary-font;
                    font-size: @primary-font-size;
                    color: @primary-content-color;
                    font-weight: 400;
                    text-decoration: none;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }
                }
            }
            .connect-mail {
                a {
                    font-family: @primary-font;
                    font-size: @primary-font-size;
                    color: #7F8FA4;
                    font-weight: 400;
                    text-decoration: none;
                }
                .btn-cross {
                    padding: 0px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-image: linear-gradient(-1deg, #F3F5F6 0%, #FFFFFF 100%);
                    border: 1px solid #D6D7E0;
                    line-height: 13px;
                    box-shadow: none;
                    margin-left: 9px;
                    vertical-align: text-bottom;
                    i {
                        color: #B2B3BF;
                        font-size: 11px;
                    }
                }
                .btn-success {
                    color: #FFFFFF;
                }
            }
        }
    }
    li:nth-child(1) {
        border-top: none;
    }
    li:nth-child(2) {
        padding: 7px 0px;
        .connect-link {
            margin-top: 7px;
        }
    }
    li:nth-child(5) {
        border-bottom: none;
    }
    li:nth-child(4) {
        padding: 7px 0px;
        .connect-link {
            margin-top: 7px;
        }
    }
}

//delete account
.accout-deactivate {
    position: relative;
    text-align: center;
    i {
        color: rgba(231, 104, 118, 0.07);
        font-size: 150px;
    }
    .delete-text {
        position: absolute;
        bottom: 50px;
        left: 0px;
        right: 0px;
        font-family: @primary-font;
        font-size: 16px;
        color: @primary-content-color;
        font-weight: 400;
    }
}

.delete-btn {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 8px;
    .btn-danger {
        background: #E76877;
        border: 1px solid #E76877;
    }
}

//page-likes
.list-group.page-likes {
    padding-left: 13px;
    padding-right: 13px;
    .list-group-item {
        border-left: none;
        border-right: none;
        border-radius: 0px;
        padding: 15px 0px;
        .connect-link {
            a {
                font-family: @primary-font;
                font-size: @primary-font-size;
                color: @primary-content-color;
                font-weight: 400;
                text-decoration: none;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }
            }
        }
        .pull-right {
            padding-top: 5px;
            .btn-primary {
                color: #FFFFFF;
                i {
                    color: #FFFFFF;
                    padding-right: 12px;
                }
            }
        }
    }
    li:nth-child(1) {
        border-top: 0px;
    }
    li:nth-child(5) {
        border-bottom: 0px;
    }
}

//email notifications
.privacy-question {
    .calendar-switch {
        padding-right: 0px;
        margin-right: 0px;
        padding-top: 5px;
    }
}

//pages-groups
.list-group {
    li:last-child {
        border-bottom: none;
    }
}

//user-settings
.mini-profile {
    .widget-bg {
        img {
            border-radius: 4px;
        }
    }
    .avatar-name {
        margin-top: -8px !important;
    }
    .avatar-mail {
        margin-top: -8px !important;
        a {
            font-family: @primary-font;
            color: #7F8FA4;
            font-weight: 400;
            font-size: 14px;
            text-decoration: none;
        }
    }
    .activity-list {
        margin-top: 17px;
        .activity-count {
            text-align: center;
        }
    }
}

.interest-accordion .panel-title a {
    font-size: 15px !important;
    cursor: pointer;
    width: 100%;
    display: inline-block;
}

.interest-accordion .panel-title a .fa {
    position: relative;
    top: 6px;
    color: #354052;
    float: right;
}

//footer
footer {
    background-color: @black;
    position: relative;
    width: 100%;
    padding: 40px 0px 80px 0px;
    float: left;
}

.footer-description {
    padding-top: 0px;
    .socialite-terms {
        a {
            color: #fff;
            font-size: 18px;
            margin-right: 36px;
            text-decoration: none;
            &:hover {
                color: #ff6c00;
            }
        }
    }
    .socialite-languages {
        span {
            color: @primary-content-color;
        }
        a {
            color: @primary-content-color;
            text-decoration: none;
            &:hover {
                color: #1991EB;
            }
        }
    }
}

//general settings
.socialite-form {
    .radio {
        label {
            input {
                margin-top: 0px;
            }
        }
        p {
            text-transform: none;
        }
    }
}

//widget-left-panel
.widget-left-panel {
    .active {
        .menu-btn {
            i {
                color: #FFFFFF !important;
            }
        }
    }
}

//general website settings
.socialite-form {
    .form-group {
        .youtube-input-group {
            .youtube-btn {
                background: #cd201f;
                border-color: #cd201f;
                i {
                    color: #FFFFFF;
                }
            }
        }
        .instagram-input-group {
            .instagram-btn {
                background: #f56040;
                border-color: #f56040;
                i {
                    color: #FFFFFF;
                }
            }
        }
        .dribbble-input-group {
            .dribbble-btn {
                background: #ea4c89;
                border-color: #ea4c89;
                i {
                    color: #FFFFFF;
                }
            }
        }
        .linkedin-input-group {
            .linkedin-btn {
                background: #0077b5;
                border-color: #0077b5;
                i {
                    color: #FFFFFF;
                }
            }
        }
    }
    .privacy-question {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        .list-group-item {
            .form-group {
                margin-bottom: 0px;
            }
        }
    }
}

//manage users
.timeline {
    .manage-table {
        td {
            font-weight: 300;
            li {
                padding-left: 0px;
                padding-right: 0px;
                a {
                    font-weight: 300;
                    font-family: @primary-font;
                    font-size: 14px;
                    padding-left: 7px;
                }
            }
        }
        td:nth-child(3) {
            padding-left: 0px;
        }
        td:nth-child(5) {
            width: 100px;
        }
    }
}

//theme-settings
.theme-block {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-bottom: 1px solid red;
    .theme-picture {
        .theme-img {
            float: left;
            img {
                width: 50px;
                height: 50px;
                border-radius: 4px;
            }
        }
        .theme-header {
            float: left;
            .theme-heading {
                font-family: @primary-font;
                font-size: @primary-font-size;
                color: @primary-content-color;
                font-weight: 400;
                margin-bottom: 0px;
            }
            h3:nth-child(1) {
                margin-top: -8px;
            }
            h3:nth-child(2) {
                margin-top: -10px;
            }
            h3:nth-child(3) {
                margin-top: -8px;
            }
        }
    }
}

.theme-passage {
    padding-left: 27px;
    padding-top: 17px;
    p {
        font-family: @primary-font;
        font-size: @primary-font-size;
        color: @primary-content-color;
        font-weight: 400;
        margin-bottom: 0px;
    }
}

//news-letter
.form-horizontal {
    padding-top: 29px;
    .form-group {
        margin-left: 12px;
        margin-right: 12px;
    }
    .form-group:nth-child(1) {
        border-bottom: 1px solid #E6EAEE;
        ;
        padding-bottom: 37px;
        margin-bottom: 21px;
    }
    .btn-success {
        padding: 6.5px 28px;
    }
}

.page-header.news-heading {
    margin: 9px 0 17px;
    padding-left: 35px;
}

//announcements
.announcements {
    .datepicker {
        padding-left: 19px !important;
        padding-right: 19px !important;
        .form-control {
            background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
            border-left: 0px;
            border-right: 0px;
        }
    }
    .form-group:nth-child(1) {
        padding-bottom: 0px;
        border-bottom: none;
    }
    .form-group {
        label {
            padding-left: 0px;
            padding-right: 0px;
        }
        .upload-form {
            input {
                margin-top: 3px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

//dash-board
.table.annual-statistics {
    tr {
        td:first-child {
            width: 200px;
            font-size: 15px;
        }
        td {
            width: 150px;
            border-top: transparent;
            padding-bottom: 18px;
            padding-top: 18px;
            .widget-sales {
                background-color: #f5f5f5;
                border-radius: 4px;
                padding-top: 0px;
                padding-bottom: 0px;
                border: 1px solid @panel-border-color;
                .no-of-sales {
                    padding-bottom: 0px;
                    padding-top: 6px;
                    font-size: 25px;
                }
                .sales {
                    padding-bottom: 10px;
                    a {
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    tr:last-child {
        td {
            border-bottom: 0px;
        }
    }
}
.interest-action-wrap {
    width: 10%;
    float: left;
    position: relative;
}
@media (max-width:1199px) {
    .accordion-admin-head {
        width: 80% !important;
    }
    .interest-action-wrap{
        width:20%;
    }
}
@media (max-width:991px) {
    .avatar-profile {
        position: relative;
        width: 100%;
        float: left;
        margin: 0;
        padding: 0;
    }
    .mini-profile .background .widget-bg {
        height: auto !important;
    }
    .negative-img-holder {
        display: block;
        margin-left: -30px;
        margin-right: -30px;
    }
    .negative-img-holder img {
        border-radius: 0px !important;
    }
}

@media (max-width:768px) {
    .interest-action-wrap{
        width:30%;
    }
    .accordion-admin-head {
        width: 80% !important;
    }
    .accordion-admin-arrow {
        margin-left: 1%;
    }
    .change-avatar,
    .change-cover {}
    .chang-user-avatar .btn-camera .avatar-text {
        display: none;
    }
    .chang-user-avatar .btn-camera {
        background-color: transparent;
    }
    .timeline-user-avtar .chang-user-avatar,
    .timeline-user-avtar:hover .chang-user-avatar {
        display: block !important;
    }
    .chang-user-avatar .btn-camera .fa {
        position: absolute;
        bottom: 0px;
        right: 10px;
    }
    .timeline-cover-section .timeline-cover .btn-camera-cover {
        background-color: transparent;
    }
    .cover-wrap {
        display: none;
    }
    .timeline-cover-section .timeline-cover .btn-camera-cover,
    .timeline-cover-section .timeline-cover:hover .btn-camera-cover {
        display: block;
    }
    .notification-text,
    .message-time {
        width: 100%;
        float: left;
    }
    .timeline-posts {}
    .timeline-posts .panel-default {
        width:100%;
        float:left;
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #E6EAEE !important;
        border-radius: 0px !important;
        margin-bottom: 0px !important;
    }
    .timeline-posts .panel-post .panel-heading {
        border: none !important;
    }
    .timeline-posts .panel-post .panel-body {
        border: none !important;
    }
    .timeline-posts .panel-post .panel-footer.socialite {
        border: none !important;
    }
    .form-left {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .yudu-logo-m img {
        position: relative;
        top: 8px;
    }
    .signup-page .navbar-brand {
        margin-top: 15px;
    }
    .common-page .navbar-brand {
        margin-top: 15px;
    }
    .common-page .navbar-custom .navbar-right {
        margin-top: 16px;
    }
}

@media (max-width:700px) {
    .table.annual-statistics {
        tr {
            td {
                width: 0px;
                .widget-sales {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}

//edit user page
.edit-form {
    .form-group {
        padding-left: 29px;
        padding-right: 29px;
    }
    .pull-right {
        padding-right: 29px;
    }
}

//manage reports
@media (max-width:620px) {
    .heading-list {
        li {
            a {
                font-size: 15px;
                padding: 33px 5px 8px 0px;
                span {
                    font-size: 10px;
                }
            }
        }
    }
}

//global declaration
.avatar-body {
    width: 78px;
    height: 70px;
    background-color: #FAFBFC;
    border: 1px solid #E6EAEE;
    border-radius: 3px;
    margin-left: 30px;
    margin-top: 9px;
    margin-bottom: 9px;
    display: inline-block;
    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        float: left;
        margin-left: -31px;
        margin-top: -1px;
    }
    .image-icon {
        padding-left: 51px;
        padding-top: 6px;
        a {
            border-bottom: 1px solid #E6EAEE;
            padding-bottom: 8px;
            i {
                color: #C8D2DF;
                font-size: 16px;
            }
        }
    }
    .image-icon.delete-icon {
        padding-top: 15px;
        a {
            border-bottom: transparent;
            i {
                font-size: 20px;
            }
        }
    }
}

.pencil-icon {
    background: grey;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    i {
        color: #FFFFFF!important;
        font-size: 14px;
        line-height: 26px;
        margin-left: 1px;
    }
}

.trash-icon {
    background: #ff6600;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    i {
        color: #FFFFFF!important;
        font-size: 13px;
        line-height: 27px;
    }
}

.announcement-container .panel-title:hover,
.admin-inner-edit-wrap a:hover {
    opacity: .8;
}

@media (max-width:600px) {
    .privacy-question {
        .list-group {
            .list-group-item {
                .form-group {
                    label {
                        padding-top: 0px;
                        padding-bottom: 15px;
                    }
                    margin-bottom: 8px;
                    .form-control {
                        float: none;
                    }
                }
            }
        }
    }
}

.settings-block {
    text-transform: capitalize;
    background: #2EA1F8;
    padding: 20px 30px;
    font-size: 18px;
    color: @default-to-color ;
    font-family: @primary-font;
    font-weight: 400;
    letter-spacing: 0.4px;
    .close-modal i {
        color: #70BBFB;
    }
}

.settings-list {
    background-color: #222C3C;
    margin-left: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    li {
        margin-left: 30px;
        margin-right: 30px;
        text-transform: capitalize;
        padding-left: 0px;
        padding-right: 0px;
        border-bottom: 3px solid transparent;
        &:first-child {
            margin-left: 10px;
        }
        &:last-child {
            margin-right: 7px;
        }
        a {
            color: #848C98;
            padding: 0px;
            border-radius: 0px;
            padding-top: @primary-font-size;
            padding-bottom: 11px;
            font-size: @primary-font-size;
            font-family: @primary-font;
            text-transform: capitalize;
            text-decoration: none;
            font-weight: 400;
        }
    }
    li.active {
        border-bottom: 3px solid #2EA1F8;
        a {
            border-radius: 0px;
            color: @default-to-color;
        }
    }
}

.settings-content {
    padding: 0px;
    margin-bottom: 0px;
    border: 0px;
}

.list-group {
    margin-bottom: 0px;
    .list-group-item {
        padding: 16px 30px;
        cursor: pointer;
        font-size: @primary-font-size;
        color: @primary-content-color;
        border-left: 0px;
        border-right: 0px;
        .text-toggle {
            opacity: 0.6;
            color: #354052;
        }
        .text-selected {
            opacity: 1;
            color: #354052;
        }
    }
}

.settings-footer {
    padding: 12px 30px;
    .btn-send {
        background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
        border: 1px solid #249533;
        border-radius: 4px;
        color: @default-to-color;
        font-size: @primary-font-size;
        font-family: @primary-font;
    }
}

@media(max-width:580px) {
    .accordion-admin-head {
        width: 70% !important;
    }
}

@media(max-width:480px) {
    .accordion-admin-head {
        width: 65% !important;
    }
    .panel-heading.panel-settings .panel-title {
        font-size: 18px;
        text-align: center;
    }
    .panel-heading.panel-settings .panel-title .btn-custom,
    .panel-heading.panel-settings .panel-title .btn-custom .btn-orange-small {
        width: 100%;
    }
    .panel-heading.panel-settings .panel-title .btn-custom .btn-orange-small {
        margin: 20px 0px;
    }
    .btn-orange-small {
        padding: 6px 17px !important;
        font-size: 15px !important;
    }
    .admin-inner-edit-wrap {
        width: 20% !important;
        float: right;
    }
    .yudu-logo-m img {
        position: relative;
        top: 0px;
    }
}

@media(max-width:379px) {
    .admin-inner-edit-wrap {
        width: 29% !important;
    }
    .interest-name {
        font-size: 13px !important;
    }
    .interest-elem div {
        font-size: 13px !important;
    }
}

//custom-pages
.btn-custom {
    float: right;
}

//user-leftbar
.side-left {
    float: left;
}

.side-right {
    float: right;
    padding-right: 10px;
}

.yudu-footer-logo {
    width: 100%;
    margin-bottom: 60px;
    float: left;
}

.login-text-head {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-top: 0px;
    color: #000000;
    text-align: center;
}

.login-text-sub-head {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #888888;
    text-align: center;
    width: 90%;
    left: 5%;
    position: relative;
    line-height: 1.4;
}

.login-errors .login-error-text {
    border: solid 1px red;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #ff0000;
    background-color: #fffafa;
    // text-transform: capitalize;
}

.login-bottom .alert-success {
    border: solid 1px #ff6c00;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #ff6c00;
    background-color: #ffefe3;
    text-transform: capitalize;
}

.custom-admin {
    position: relative;
    padding-top: 7px;
}

.custom-admin-logo {
    padding-top: 13px !important;
}

.accordion-admin-head {
    float: left;
    width: 90%;
}

.accordion-admin-arrow {
    margin-left: 2%;
}

.interest-name {
    width: 100%;
    float: left;
    padding-right: 30px;
    font-size: 16px;
    color: #ff6600;
}
.interest-name__count{
    float: right;
    color: #666;
    font-size: 14px;
    font-weight: 500;
}
.interest-name__count span{
    color:#000;
    font-size:16px;
}

.interest-description {
    width: 60%;
    float: left;
}

.admin-inner-edit-wrap {
    width: 15%;
    float: right;
}

.orange-color {
    background-color: #ff6600 !important;
}

.grey-color {
    background-color: grey !important;
}

.interest-accordian {
    width: 100%;
    display: inline-block;
    cursor: pointer;
}

.home-left-bar {
    padding-bottom: 3px;
    width: 94%;
    margin-left: 3%;
    margin-top: 0;
    margin-bottom: 0;
}

.home-left-bar.menu-list ul {
    margin-bottom: 0px;
}

.home-left-bar.menu-list li a {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.home-left-bar.menu-list li.active .menu-btn {
    background-color: #ff6c00
}
.home-left-bar.menu-list li .menu-btn {
    background-color: transparent;
    color: #000;
}

.home-left-bar.menu-list li .menu-btn i {
    color: #7d7d7d !important;
}

.home-left-bar.menu-list li {
    border-top: solid 1px #e5e5e5;
}

.home-left-bar.menu-list li:first-child {
    border-top: none;
}

.home-left-bar.menu-list li.active .menu-btn i {
    color: #000 !important;
}

.home-left-bar.menu-list li .menu-btn {
    padding: 10px 14px;
}

.home-left-bar.menu-list li .menu-btn:hover {
    background-color: transparent !important;
    color: #ff6c00 !important;
}

.home-left-bar.menu-list li .menu-btn:hover i {
    color: #ff6c00 !important;
}

.home-left-bar.menu-list li .event-circle {
    line-height: 20px !important;
    border-radius: 3px !important;
}

.yudu-outer-wrap .panel-body {
    padding: 0px !important;
    background-color: #fbfbfb;
}

.yudu-outer-wrap .panel-heading {
    font-size: 18px;
    font-weight: 400;
    border: none;
    background-image: none;
    background-color: transparent;
    padding: 16px 17px;
    color: #000;
}

.yudu-outer-wrap .categotry-list {
    padding-left: 0px !important;
}

.yudu-outer-wrap .custom-error {
    width: 90%;
    margin-left: 5%;
    font-size: 15px;
    padding: 13px 0px;
    background-color: #ececec;
    float: left;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 0px;
    border-radius: 6px;
}

.yudu-outer-wrap .custom-error .text-warning {
    color: #000;
}

.yudu-timeline-right .avatar-profile .avatar-details .avatar-name {
    margin: 0px 0px 7px 0px;
}

.yudu-timeline-right .avatar-profile .avatar-details .avatar-name a {
    color: #000;
    font-size: 18px;
}

.yudu-timeline-right .avatar-profile .avatar-details {
    margin-top: 16px;
}

.yudu-timeline-right .background .avatar-img {
    bottom: -40px;
}

.yudu-timeline-right .avatar-profile .avatar-details a {
    font-size: 16px;
}

.yudu-timeline-right .activity-list li {
    padding-left: 0px;
    padding-right: 0px;
    width: 32%;
    text-align: center;
}

.yudu-timeline-right .activity-list li .activity-name,
.yudu-timeline-right .activity-list li .activity-count {
    text-align: center;
}

.yudu-timeline-right .activity-list li .activity-count {
    color: #a0a0a0;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 6px;
}

.yudu-timeline-right.socialite .activity-list {
    margin-top: 18px;
}

.yudu-people {
    padding: 20px 10px !important;
}

.yudu-people .yudu-follow {
    background-image: none;
    border: solid 1px #ff6c00;
    color: #ff6c00 !important;
    font-size: 12px;
    border-radius: 3px;
}

.yudu-people .yudu-follow:hover {
    background-color: #ff6c00;
    border: solid 1px #ff6c00;
    color: #fff !important;
}

.yudu-people .yudu-unfollow {
    background-image: none;
    background-color: #ff6c00 !important;
    border: solid 1px #ff6c00 !important;
    color: #fff !important;
    font-size: 12px;
    border-radius: 3px;
}

.yudu-people .yudu-unfollow:hover {
    background-color: #808080;
    border: solid 1px #808080;
    color: #000 !important;
}

.yudu-people .badge-verification {
    padding-top: 0px;
}

.yudu-people .media-heading {
    /*  margin-top: 12px; */
}

.btn-orange-medium {
    font-size: 14px !important;
    text-transform: uppercase !important;
    color: #fff;
    background-color: #ff6c00 !important;
    font-weight: 400 !important;
    padding: 12px 30px !important;
    padding: 6px 29px !important;
    border-radius: 3px !important;
}

.btn-orange-medium:hover {
    opacity: .8;
    color: #fff !important;
}

.dropdown-menu {
    border-radius: 0px !important;
}

.user-bio-block .bio-list li span {
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.user-bio-block .bio-list li i {
    color: #ff6c00;
    font-size: 22px;
}

.user-bio-block .bio-list li {
    margin-bottom: 0;
    position: relative;
    padding: 10px 0px;
    border-top: solid 1px #ddd;
}

.user-bio-block .bio-header {
    color: #000;
    font-weight: 600;
    font-size: 17px;
}

.user-bio-block .bio-description {
    padding: 13px 0px;
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
}

.yudu-outer-wrap .categotry-list ul li {
    line-height: 1.4;
    display: inline-block;
    width: 100%;
    padding: 8px 0px 8px 5px;
    border-top: solid 1px #e5e5e5;
}

.yudu-outer-wrap .categotry-list ul li:first-child {
    border-top: none;
}

.yudu-outer-wrap .categotry-list li .hash-icon {
    background-color: transparent;
}

.yudu-outer-wrap .categotry-list li .hash-icon i {
    color: #ff6c00;
    font-size: 11px;
    font-weight: 400;
}

.yudu-outer-wrap .categotry-list li .hash-icon .fa-caret-right {
    color: #ff6c00;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    top: 4px;
}

.yudu-outer-wrap .categotry-list li a {
    font-weight: 400;
    font-size: 14px;
    width: 86%;
}

.yudu-outer-wrap .categotry-list li a:hover {
    color: #ff6c00;
}

.jscroll-added {
    margin: 20px 0px;
}

.follow-links .follow {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #ff6c00 !important;
    color: #fff !important;
    font-size: 14px;
}

.follow-links .follow:hover {
    background-color: #fff !important;
    color: #ff6c00 !important;
}

.follow-links .btn-default {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #fff !important;
    color: #ff6c00 !important;
    font-size: 12px;
}

.follow-links .btn-default:hover {
    background-color: #ff6c00 !important;
    color: #fff !important;
}

.follow-links .unfollow {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #ff6c00 !important;
    color: #fff !important;
    font-size: 14px;
}

.follow-links .unfollow:hover {
    background-color: #fff !important;
    color: #ff6c00 !important;
}

.join-links .join-user {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #ff6c00 !important;
    color: #fff !important;
    font-size: 14px;
}

.join-links .join-user:hover {
    background-color: #fff !important;
    color: #ff6c00 !important;
}

.join-links .btn-default {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #fff !important;
    color: #ff6c00 !important;
    font-size: 12px;
}

.join-links .btn-default:hover {
    background-color: #ff6c00 !important;
    color: #fff !important;
}

.pagelike-links .page-like {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #ff6c00 !important;
    color: #fff !important;
    font-size: 14px;
}

.pagelike-links .page-like:hover {
    background-color: #fff !important;
    color: #ff6c00 !important;
}

.pagelike-links .btn-default {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #fff !important;
    color: #ff6c00 !important;
    font-size: 12px;
}

.pagelike-links .btn-default:hover {
    background-color: #ff6c00 !important;
    color: #fff !important;
}

.btn-outline-orange {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #fff !important;
    color: #ff6c00 !important;
    font-size: 14px;
    margin: 0px 5px;
}

.btn-outline-orange:hover {
    background-color: #ff6c00 !important;
    color: #fff !important;
}

.btn-solid-orange {
    background-image: none;
    border: solid 1px #ff6c00 !important;
    background-color: #ff6c00 !important;
    color: #fff !important;
    font-size: 14px;
    margin: 0px 5px;
}

.btn-solid-orange:hover {
    background-color: #fff !important;
    color: #ff6c00 !important;
}

.btn-solid-grey {
    background-image: none;
    border: solid 1px #626978 !important;
    background-color: #626978 !important;
    color: #fff !important;
    font-size: 14px;
    margin: 0px 5px;
}

.btn-solid-grey:hover {
    background-color: #fff !important;
    color: #626978 !important;
}

.upd-pro-text,
.upd-sugg-dim {
    width: 94%;
    left: 3%;
    float: left;
    position: relative;
    margin-top: 4px;
    word-break: break-all !important;
}

.upd-sugg-dim {
    margin-top: 5px;
}

.cover-wrap {
    position: relative;
    float: right;
}

.cover-wrap .change-cover-size {
    text-transform: capitalize;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

#noty_bottomLeft_layout_container li {
    background-color: transparent !important;
    border: solid 0px transparent !important;
}

.noty_type_success {
    background-color: #ffe0ca;
    border: solid 1px #ff6c00;
    color: #ff6c00;
}

.alert-success {
    background-color: #ffefe3;
    border-color: #ff6c00;
    color: #ff6c00;
}

.alert-info {
    background-color: #ffefe3;
    border-color: #ff6c00;
    color: #ff6c00;
}

.alert-danger {
    background-color: #fffafa;
    border-color: #ff0000;
    color: #ff0000;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #fff !important;
    text-decoration: none;
    outline: 0;
    background-color: #ff6c00;
}

.album-file-upload {
    padding: 30px;
    margin: 10px 0px;
    background: #FFFCF9;
    border: 1px dashed #F3D5BF;
    border-radius: 4px;
    width: 100%;
    float: left;
}

.album-file-upload .upload-icon i {
    font-size: 45px;
    color: #ff6c00;
}

.album-file-upload input {
    position: absolute;
    top: -86px;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 104px !important;
    direction: ltr;
    cursor: pointer;
}

.album-file-upload .upload-area {
    position: relative;
}

.album-file-upload .upload-area h4 {
    color: #000;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    text-transform: capitalize;
    font-size: 12px;
    padding:0 90px;
}

@media (max-width: 1360px) {
    .mini-profile.socialite .activity-name {
        font-size: 12px;
    }
}

@media (max-width: 440px) {
    .mini-profile.socialite .activity-name {
        font-size: 12px;
    }
    .media-body.socialte-timeline .text-muted {
        font-size: 13px;
    }
    .user-follow.socialite .media-left img {
        width: 22px;
        height: 22px;
    }
    .btn-follow .btn {
        padding: 4px 4px;
    }
}

@media (max-width: 1359px) and (min-width: 1200px) {
    .activity-list {
        padding: 0 !important;
        margin: 0;
    }
    .mini-profile.socialite .activity-name {
        font-size: 10px;
    }
}

.custom-search-w {
    width: 43%;
    float: left;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.custom-table-resp {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 26px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 2;
    color: #fff;
    background-color: #ff6c00;
    border-color: #ff6c00;
    cursor: default;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #ff6c00;
}

.yudu-social-header.navbar-default .register-login.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 1010px) {
    .form-left .form-control .selectize-dropdown {
        width: 100% !important;
    }
    .mobile-navigation {
        position: relative;
        float: right !important;
        margin: 0;
        padding: 0;
    }
    .custom-search-padding .navbar-form {
        margin-right: 0px !important;
    }
    .mobile-navigation li {
        width: auto;
        display: inline-block;
        margin: 10px 0px 0px 0px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .mobile-navigation li:first-child {
        padding-left: 20px !important;
    }
    .custom-search-padding {
        padding: 10px 0px 10px 0px !important;
    }
    .navbar-toggle {
        margin-left: 14px;
    }
    .dropdown.message {
        padding-right: 0px !important;
    }
    .navbar-custom .yudu-social-header .navbar-right {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .user-image.dropdown.socialite .dropdown-menu {
        margin-top: 0px;
    }
    .navbar.socialite {
        padding-bottom: 10px;
    }
    .yudu-social-header.navbar-default .navbar-nav > li > a {
        padding: 10px 22px !important;
    }
    .yudu-social-header.navbar-default .navbar-nav > li {
        height: auto !important;
    }
    .home-link-li {
        padding: 0px 0px 0px 8px !important;
        position: relative;
        float: left;
        width: 100%;
        margin: 8px 0px 8px 0px;
    }
    .notification-list > li > a {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .yudu-social-header.navbar-default .navbar-nav > li > a .fa {
        margin-left: 8px;
    }
    .notification-list > li .small-screen {
        color: #fff;
    }
    .selectize-control.form-control.multi {
        width: 100% !important;
    }
}

@media (max-width: 1010px) {
    .mobile-profile-view {
        background-color: #fff;
        width: 100%;
        border-top: 2px solid #ff6c00;
        padding-top: 0px;
        padding-bottom: 0px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
        padding-left: 0px;
        position: relative;
        float: left;
    }
    .mobile-profile-view li {
        border-bottom: 1px solid #E6EAEE;
        margin-left: 0px;
        padding-left: 0px !important;
        position: relative;
        float: left;
        width: 100%;
        margin-left: 0px !important;
    }
    .mobile-profile-view li a {
        margin-left: 0px;
        padding: 10px 10px 10px 2px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0px;
        text-transform: capitalize;
        position: relative;
        float: left;
        width: 100%;
    }
    .mobile-profile-view li:hover {
        background-color: #ff6c00;
    }
    .mobile-profile-view li:hover a,
    .mobile-profile-view li:hover i {
        color: #fff;
    }
    .mobile-profile-view li a i {
        padding-right: 15px;
        padding-left: 15px;
    }
    .mobile-notification-wrapper {
        width: 100%;
        height: auto;
        background: #f7f7f7;
        border-bottom: solid 1px #e3e3e3;
        float: left;
        margin-bottom: 20px;
        margin-top: 60px;
        position: relative;
    }
    .mobile-notification-wrapper .mobile-navigation {
        width: 100% !important;
        float: left !important;
    }
    .mobile-notification-wrapper .mobile-navigation li:first-child {
        padding-left: 0px !important;
        border-left: none !important;
    }
    .mobile-notification-wrapper .mobile-navigation li {
        width: 25%;
        text-align: center !important;
        border-left: solid 1px #e3e3e3;
        margin-top: 0px !important;
        padding: 10px 0 !important;
        margin: 0 !important;
    }
    .mobile-notification-wrapper .mobile-navigation li a i {
        color: #454545 !important;
        font-size: 30px !important;
    }
    .mobile-notification-wrapper .dropdown.message .dropdown-menu {
        margin-top: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 1010px) {
    .navbar-custom .yudu-social-header .navbar-right {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 768px) {
    /*Mobile Fonts*/
    .panel-post .panel-heading .post-author .user-post-details li .post-time {
        color: #444 !important;
    }
    .panel-post .panel-body .actions-count li a {
        color: #b0b0b0 !important;
    }
    .media-heading a {
        color: #1d2129 !important;
    }
    .panel-post .panel-body .text-wrapper p {
        color: #1d2129 !important;
    }
    .panel-post .panel-body .actions-count li a .count-circle {
        color: #fff !important;
        background-color: #b0b0b0;
    }
    .panel-post .panel-heading .post-author .user-post-details li .user {
        font-weight: 600;
    }
    .panel-post .panel-heading .post-author .user-post-details li {
        color: #888888 !important;
    }
    .panel-post .comments-section .comments-wrapper .commenter-name .comment-description {
        color: #1d2129 !important;
    }
    .panel-post .comments-section .comments-wrapper .comment-options li,
    .panel-post .comments-section .comments-wrapper .comment-options li a {
        color: #1d2129 !important;
    }
    .panel-post .panel-footer.socialite .footer-list li a {
        color: #a0a0a0 !important;
    }
    .panel-post .panel-footer.socialite .footer-list li a i {
        color: #a0a0a0 !important;
    }
}

@media (max-width: 767px) {
    .navbar.socialite {
        padding-bottom: 0px;
    }
    .selectize-control.form-control.multi {
        width: 100% !important;
    }
    .side-left {
        display: none !important;
    }

    .chat-box-header .side-left-visible {
        display: block !important;
    }
    .connect-list .side-left-visible {
        display: block !important;
    }
    .custom-group-list .side-left {
        display: block !important;
    }
    .page-suggested-users .side-left-visible {
        display: block !important;
    }
    .group-suggested-users .side-left-visible {
        display: block !important;
    }
    .widget-pictures .side-left-visible {
        display: block !important;
    }
    .mobile-notification-wrapper{
        margin-top:60px;
    }
}

@media (max-width: 1010px) and (max-width: 768px) {
    .navbar-form {
        margin-left: auto;
        width: auto;
    }
}

.panel-post .panel-body iframe {
    width: 100%;
    margin-top: 10px;
    height: auto;
}

.embed-responsive.embed-responsive-16by9 iframe {
    margin-top: 0 !important;
    height: 100% !important;
}

.home-link {
    position: relative;
    top: 5px;
}

.divider-login .divider-text:before {
    background-color: transparent !important;
}

.yudu_or {
    position: relative;
    width: 100%;
    margin-top: 20px;
    float: left;
}

.yudu_or .or_inner {
    position: relative;
    width: 100px;
    height: 1px;
    background-color: #ccc;
    margin: 0 auto;
}

.yudu_or .or_inner:after {
    position: absolute;
    content: "OR";
    width: 30px;
    height: 30px;
    top: -15px;
    left: 34%;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #ccc;
    float: left;
    font-size: 12px;
    background-color: #fff;
    line-height: 28px;
}

.individual-image-wrap {
    position: relative;
    float: left;
    margin-left: 10px;
}

.individual-image-wrap .fa {
    color: #ff6c00;
    position: relative;
    top: 6px;
    font-size: 18px;
}

.generic-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    white-space: nowrap;
    background: #ebeff1;
    display: block;
}

.generic-popup:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.generic-popup .generic-popup-content {
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    overflow: auto;
    padding: 40px 25px;
    margin-left: 5%;
    margin-right: 5%;
    background: #fff;
    white-space: normal;
    border: 1px solid #dddddd;
    width: 625px;
    text-align: center;
}

@media (max-width: 1024px) {
    .generic-popup .generic-popup-content {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
        max-height: 98%;
    }
}

.generic-popup__icon {
    float: left;
    width: 100%;
}

.generic-popup__icon img {
    vertical-align: top;
}

.generic-popup__head {
    float: left;
    width: 100%;
    font-size: 42px;
    font-weight: 600;
    color: #ff6c00;
    margin-top: 13px;
    margin-bottom: 5px;
}

.generic-popup__notif-text {
    float: left;
    width: 100%;
    font-size: 26px;
    font-style: italic;
    color: #5a5a5a;
    margin-bottom: 10px;
}

.generic-popup__text {
    float: left;
    width: 100%;
    font-size: 16px;
    color: #5a5a5a;
}

.generic-popup__text a {
    display: inline-block;
    color: #3dc593 !important;
    vertical-align: top;
    text-decoration: underline;
}

.generic-popup__error {
    font-size: 18px;
    text-decoration: underline;
    margin: 10px 0px;
}

.time-stamp {
    font-size: 12px;
    color: #868686;
    font-weight: 400;
}

@media (max-width: 480px) {
    .btn-solid-orange,
    .btn-solid-grey {
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .btn-outline-orange,
    .btn-outline-grey {
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .mobile-navigation {
        margin-right: 20px;
    }
    .mobile-navigation li {
        padding-left: 20px !important;
        padding-right: 10px !important;
        margin-top: 9px !important;
    }
    .mobile-navigation li:first-child {
        padding-left: 10px !important;
    }
    .yudu-social-header .navbar-brand.yudu-logo-m {
        padding: 5px 0px 3px 0px !important;
    }
    .navbar-toggle {
        margin-left: 0px;
        margin-right: 10px;
        margin-top: 12px;
    }
    .custom-friend {
        width: 100%;
        float: left;
    }
    .request-right {
        padding-top: 0px;
    }
    .navbar-brand {
        width: 100px;
    }
    .navbar .navbar-btn-join,
    .btn.btn--solid-grey {
        min-width: 0;
        font-size: 12px !important;
        padding: 6px 8px !important;
    }
    .navbar-custom .navbar-right {
        margin-top: 14px !important;
    }
    .navbar-brand img {
        max-width: 100%;
    }
    .signup-page .navbar-brand {
        width: 110px;
    }
}

@media (max-width: 320px) {
    .navbar-toggle {
        margin-right: 0px;
        margin-top: 12px;
    }
    .navbar-brand {
        width: 90px;
    }
    .common-page .navbar-brand {
        width: 110px;
    }
    .signup-page .navbar-brand {
        width: 110px;
    }
    .signup-page .navbar-toggle {
        margin-top: 19px;
        margin-right: 15px;
    }
    .heading-list li:first-child a {
        padding-left: 0px;
    }
    .heading-list li a {
        font-size: 14px;
        padding: 33px 2px 8px 0px;
    }
    .mobile-notification-wrapper .mobile-navigation li a i {
        color: #454545 !important;
        font-size: 24px !important;
    }
}

.open {
    display: block;
}

.custom-mt30 {
    margin-top: 30px;
}

.custom-assign-btn {
    padding-right: 40px;
}

.delete-page {
    padding: 0px 10px;
}

.custom-page-like .list-text {
    text-transform: none !important;
}

.dropdown-backdrop {
    position: static;
}

.custom-group-list {
    width: 100%;
    float: left;
}

.custom-group-list .list-group-item {
    width: 100%;
    float: left;
    padding: 6px 0;
}

.custom-group-list .list-group-item .connect-link a img {
    width: 50px !important;
    height: 50px !important;
    margin-right: 9px;
}

.custom-group-list .connect-list {
    width: 80%;
    float: left;
}

.custom-group-list .group-type {
    position: relative;
    float: right;
    right: 20px;
}

.custom-group-list .custom-approve {
    position: relative;
    float: right;
    right: 20px;
    margin-top: 10px;
}

.custom-group-list .custom-close {
    position: relative;
    float: right;
    right: 10px;
}

.text-danger {
    color: #ff0000;
}

.text-decline {
    color: #282828;
    margin-left: 6px;
}

.notification-link {
    color: #000;
}

.widget-events.widget-left-panel .menu-list li .event-circle {
    position: absolute;
    float: right;
    right: 33px;
}

.temp-hidden {
    display: none;
}

.submit-cover {
    position: absolute;
    bottom: 10px;
    float: right;
    right: 10px;
}
@media( max-width:767.98px){
    .w-100{
        width: 100% !important;
    }
}
.pill-label {
    padding: 6px 12px 4px;
    border-radius: 12px;
}
.pill-label.label-success {
    background-color: #45cc49 !important;
}
.two-col-layout {
    word-wrap: break-word;
    flex-wrap: wrap;

    .panel-title {
        width: auto !important;
        max-width: 100%;
    }
}
.adcenter-modal{
    .modal-dialog{
        max-width: 624px;
        .modal-content{
            border-radius: 10px;
        }
        .modal-title{
            font-size: 18px;
            font-weight: 600;
            font-family: 'Source Sans Pro', sans-serif;
            span{
                color: #9B9B9B;
                font-weight: normal;
                em{
                    color: #FF6C00;
                    font-weight: 600;
                    font-style: normal;
                }
            }
        }
        .modal-header{
            padding:15px 25px;
            .close{
                font-size: 30px;
            }
        }
        .modal-body{
            padding:15px 25px;
            word-wrap: break-word;
        }
        .form-group label, fieldset label{
            font-size: 12px;
        }
        .form-group .label_title{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #ff6600;
        }
        .form-control{
            height: 36px;
        }
        .radio-toolbar input[type="radio"] {
            opacity: 0;
            position: fixed;
            width: 0;
        }

        .radio-toolbar label {
            display: inline-block;
            background-color: #fff;
            padding: 10px 20px;
            font-size: 12px;
            border: 1px solid #888;
            border-radius: 4px;
            margin-right: 15px;

        }

        .radio-toolbar label:hover {
            background-color: #ff6c00;
            color: #fff;
        }

        .radio-toolbar input[type="radio"]:focus+label {
            border: 2px dashed #888;
        }

        .radio-toolbar input[type="radio"]:checked+label {
            background-color: #ff6c00;
            border-color: #ff6c00;
            color: #fff;
        }
        .btn_continue{
            padding: 9px 25px;
            font-family: 'Source Sans Pro', sans-serif;
        }
        .audiance-accordion{
            position: relative;
            width: 100%;
            float: left;
            background: #FCFCFC;
            border: 1px solid #ECECEC;
            border-radius: 3px;
            margin-bottom: 10px;
            .audiance-accordion--item{
                display: flex;
                justify-content: space-between;
                padding:17px 10px;
            }
            .audiance-item-inner{
                position: relative;
                width: 100%;
                float: left;
                 display: flex;
                 align-items: center;
                .arrow{
                    position: relative;
                    width: 30px;
                    height: 30px;
                    border: solid 1px #d5d5d5;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .audiance-head{
                    position: relative;
                    float: left;
                    margin-left:15px;
                    h4{
                        font-weight: 600;
                        font-size: 18px;
                        font-family: 'Source Sans Pro', sans-serif;
                        margin:0;
                        padding:0
                    }
                    h5 {
                        font-weight: 400;
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                        color: #737373;
                    }
                }

            }
             .audiance-switch {
                 display: flex;
                 align-items: center;
             }
             .switch {
                 position: relative;
                 display: inline-block;
                    width: 50px;
                    height: 25px;
                    margin: 0 !important;
             }

             .switch input {
                 opacity: 0;
                 width: 0;
                 height: 0;
             }

             .slider {
                 position: absolute;
                 cursor: pointer;
                 top: 0;
                 left: 0;
                 right: 0;
                 bottom: 0;
                 background-color: #ccc;
                 -webkit-transition: .4s;
                 transition: .4s;
             }

             .slider:before {
                 position: absolute;
                 content: "";
                height: 19px;
                width: 19px;
                left: 3px;
                bottom: 3px;
                 background-color: white;
                 -webkit-transition: .4s;
                 transition: .4s;
             }

             input:checked+.slider {
                 background-color: #FF6C00;
             }

             input:focus+.slider {
                 box-shadow: 0 0 1px #FF6C00;
             }

             input:checked+.slider:before {
                 -webkit-transform: translateX(26px);
                 -ms-transform: translateX(26px);
                 transform: translateX(26px);
             }

             /* Rounded sliders */
             .slider.round {
                 border-radius: 50px;
             }

             .slider.round:before {
                 border-radius: 50%;
             }
             .audiance-accordion-sub-item{
                 position: relative;
                 width: 100%;
                 float: left;
                 border-top: solid 1px #E9E9E9;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px;
                .audiance-accordion-sub-item__head{
                    position: relative;
                    float: left;
                    margin-left:9%;
                    h4{
                        margin:0;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 19px;
                    }
                    h5{
                        margin:0;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #737373;
                    }
                }
                .aud-amount{
                    font-family: 'Source Sans Pro', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 19px;
                    color: #7A7A7A;
                    margin-right:10px;
                }
             }

        }
    }
}

  .error {
      color: red !important;
  }
  .back-btn {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      top:8px;
      color: #7E7E7E !important;
  }

  .media-dropdown {
      color: #000;
      font-size: 14px;
      line-height: 1;
  }

  .media-dropdown .dropdown-toggle {
      position: relative;
      padding-right: 15px;
      color: #000;

  }

  .media-dropdown .dropdown-toggle:Before {
      content: "";
      position: absolute;
      right: 0;
      top: 5px;
      margin-left: 2px;
      vertical-align: middle;
      border-bottom: 4px solid #FF6C00;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
  }

  .media-dropdown .dropdown-toggle:after {
      content: "";
      position: absolute;
      right: 0;
      top: 11px;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid #FF6C00;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
  }

  .media-dropdown .dropdown-menu {
      left: auto;
      margin:0 !important;
      padding:0 !important;
      border-radius: 6px !important;
      overflow: hidden;

  }
  .summery_duration_toggle{
      padding: 8px 20px !important;
    h5 {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: #000000;
        margin: 0;
    }

    p {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 10px;
        color: #000000;
        margin:0;
    }
  }
  .upload-photo{
          padding: 3px 10px;
  }
  .widget-card{
      padding: 0px !important;
      border-radius: 6px;
      overflow: hidden;
      border: none !important;
  }
  .photo_video_preview .panel-default{
      border-color: transparent !important;
  }
  .preview-tab{
      border-bottom: solid 1px #d5d5d5;
  }
    .preview-tab.nav-pills>li.active>a,
    .preview-tab.nav-pills>li.active>a:hover,
    .preview-tab.nav-pills>li.active>a:focus {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        color: #FF6C00;
        border-bottom: solid 2px #FF6C00;
        border-radius: 0px;
        }
    .tab-content--preview-ad{
            padding: 0 !important;
            margin-top: 30px;
            border: none;
            border-radius: 0;
            background: #FBFBFB;
            border: 1px solid #ECECEC;
                position: relative;
                float: left;
                width: 100%;
    }
    .preview-image{
        position: relative;
        width: 142px;
        height: 138px;
        float: left;
        overflow: hidden;
        img{
            max-width: 100%;
        }
    }
    .preview-details{
        position: relative;
        float: right;
        width: 70%;
        #ad_preview_title{
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: #000000;
            mix-blend-mode: normal;
            opacity: 0.59;
            text-transform: uppercase;
            margin-top: 25px;
        }
        #ad_preview_description {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            margin-bottom: 25px;
        }
    }
    .margin-left-0{
        margin: 0;
    }


    .preview-modal {
        .modal-dialog {
            max-width: 424px;

            .modal-content {
                border-radius: 10px;
            }

            .modal-title {
                font-size: 18px;
                font-weight: 600;
                font-family: 'Source Sans Pro', sans-serif;

                span {
                    color: #9B9B9B;
                    font-weight: normal;

                    em {
                        color: #FF6C00;
                        font-weight: 600;
                        font-style: normal;
                    }
                }
            }

            .modal-header {
                padding: 15px 25px;
            }

            .modal-body {
                padding: 15px 25px;
            }
            .modal-content{
                margin-bottom: 10px;
            }
        }
    }
    .summary-table{

        background: #FBFBFB;
        border: 1px solid #ECECEC;
        margin-bottom: 30px;
        td{
            font-weight: normal !important;
        }
    }
    .summary-head{
        font-family: 'Source Sans Pro', sans-serif !important;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 23px;
        color: #000000 !important;
        padding: 10px 5px;
        border-bottom: solid 1px #ECECEC;

    }
        .summary-data1{
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal !important;
            font-size: 15px;
            line-height: 19px;
            text-align: left;
            color: #000;
            text-indent: 8px;
            padding: 10px 5px;
            border-bottom: solid 1px #ECECEC;

        }
    .summary-data2 {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: normal !important;
        font-size: 15px;
        line-height: 19px;
        text-align: right;
        color: #7A7A7A;

        padding: 10px 5px;
        border-bottom: solid 1px #ECECEC;

    }

    .payment-row{
         padding: 10px 5px;
          background: #FBFBFB;
         border-bottom: solid 1px #ECECEC;
         .p-col{

         }
    }
.margin-bottom-0{
    margin-bottom: 0px !important;
}
.add-new-payment{
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: right;
    color: #FF6C00;
}
.margin-top-10{
    margin-top:10px;
}
.secure-ssl{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1;
    color: #ff6c00;
    i{
        margin-right:6px;
    }
}

@media( max-width:576px){
    .adcenter-modal .modal-dialog .radio-toolbar label{
        width:100%;
        text-align: center;
    }
    .album-file-upload .upload-area h4{
        padding:0px;
    }
    .preview-image{
        width: 100%;
    }
    .preview-details {
        width: 100%;
        padding: 0 20px 20px 20px;
    }
}
.adcenter-admin-outer{
    padding:45px 30px;
    .adcenter-admin-header{
        position: relative;
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
            margin:0;
            padding:0;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 33px;
            color: #000000;
        }
        h4{
            margin:0;
            padding:0;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
        }
    }
    .adcenter-section--admin{
        h4{
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
        }
    }
    .adcenter-admin--table{
        background: #FFFFFF;
        border: 1px solid #E6EAEE;
        box-sizing: border-box;
        thead{
            th{
                background-color: transparent;
                font-family: 'Source Sans Pro', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #7F8FA4;
                padding: 19px 8px;
                text-align: center;
                &:first-child{
                    text-align: left;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-family: 'Source Sans Pro', sans-serif;
                    font-style: normal;
                    font-weight: normal !important;
                    font-size: 13px;
                    line-height: 16px;
                    padding: 19px 8px;
                    color: #7F8FA4;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                        font-weight: 600 !important;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}
.adcenter-admin-outer .adcenter-admin--table tbody tr td:first-child {
    width: 28% !important;
}
.adcenter-admin-outer .adcenter-admin--table tbody tr td.status {
    width: 25% !important;
    text-align: left !important;
}

.adcenter-admin-outer .adcenter-admin--table thead tr th.text-left {
    text-align: left !important;
}
.color--green{
    color: #00B13F !important;
}
.color--red {
    color: #ED3131 !important;
}
.mt-20{
    margin-top:20px !important;
}
.ad-detail-info{
    background: #FFF5EE;
    border: 1px solid #FAE8DB;
    border-radius: 5px;
    padding:10px 5px;
}
.ad-detail-form [class*='col-'] .form-group{
    border-bottom: 1px solid #E9E9E9;
}
.ad-detail-form [class*='col-'] .form-group label{
    margin-bottom: 10px;
}
.my-4{
    margin:10px 0px 0 0;
}
.ad-detail--text{
 font-family: 'Source Sans Pro', sans-serif;
 font-style: normal;
 font-weight: normal !important;
 font-size: 16px;
 line-height: 20px;
 padding-bottom:12px;
 color: #000;
}
.ad-detail-image{
    position: relative;
    width: 100%;
    float: left;
    padding-bottom: 12px;
    img{
        width:128px;
        height: 128px;
        border-radius: 6px;
    }
}
body .order-summery-head{
    font-family: 'Source Sans Pro',
    sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600 !important;
}

.yd-custom-radio {
    position: relative;
    padding-left: 24px;
    margin-bottom: 12px;
    margin-right:15px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.yd-custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: solid 1px #252525;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.yd-custom-radio:hover input~.checkmark {
    background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.yd-custom-radio input:checked~.checkmark {
    background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.yd-custom-radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.yd-custom-radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff6c00;
}
.adcenter-close{
    right: 20px !important;
    font-size: 20px;
}
 .yd-orange{
    font-size:18px !important;
 }

 /*************************************************/
 /************* YUDU Nav bar **********************/
 /*************************************************/
 .yudu-mobile-navbar {
    background-image: linear-gradient(rgba(0, 0, 0, .9) 49%,rgba(0, 0, 0, .3) 71%, rgba(0, 0, 0, 0) 100%) !important;
    background-image: -moz-linear-gradient(rgba(0, 0, 0, .9) 49%,rgba(0, 0, 0, .3) 71%, rgba(0, 0, 0, 0) 100%) !important;
    border: none !important;
    height: 100px;

    .navbar-toggle {
        border: none;
    }
 }

 /**************************************************/
 /************  Mission page styles ****************/
 /**************************************************/
 .mission-hero {
    .main-header {
        text-shadow: 0px 0px 30px #000;
    }
    .sub-header {
        color: #fff;
        font-size: xx-large;
        text-shadow: 0px 0px 30px #000;
    }

    .missionVideo {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .container {
        position: absolute;
        bottom: 15%;
        background: rgba(0, 0, 0, 0);
        color: #f1f1f1;
        width: 100%;
    }
 }

 .the-yudu-feed {
    background: rgba(255, 255, 255, 1) !important;
    
    .main-header {
        color: #ff6600;
        font-size: 45px;
    }
    .sub-header {
        color: #ff6600;
        font-size: xx-large;
    }

    .yudu-feed-review {
        background-color: #F97F29;
        color: #fff;
        height: 175px;
        border-radius: 1rem;
        padding: 2rem;
        p {
            color: #fff;
            font-size: 16px;
        }
    }

    .mobile-yudu-mission-about {
        padding: 2rem;
    }
    
    .mobile-yudu-feed-review {
        background-color: #F97F29;
        color: #fff;
        height: 255px;
        padding: 4rem;
        p {
            color: #fff;
            font-size: 16px;
        }
    }
}

.mobile-yudu-share-follow {
    background-color: #F97F29;
    color: #fff !important;
    padding: 4rem;
    min-height: 450px;
    text-shadow: 0px 0px 225px #222;

    .main-header {
        color: #fff;
    }
    .sub-header {
        color: #fff;
    }
    p {
        color: #fff;
        font-size: 21px;
    }
}

.mobile-yudu-mission-phone-preview {
    padding: 4rem 0rem;
    img {
        width: 75%;
    }
}

.wider-section--share-and-follow {
    .main-header {
        color: #fff;
        font-size: 45px;
    }
    .sub-header {
        color: #fff;
        font-size: xx-large;
    }
    p {
        color: #fff;
        font-size: 21px;
    }
}

.yudu-exclusive-content {
    .main-header {
        color: #ff6600;
        font-size: 45px;
    }
    .sub-header {
        color: #ff6600;
        font-size: x-large;
    }
}

.mobile-yudu-exclusive {
    .main-header {
        color: #ff6600;
        font-size: 45px;
    }
    .sub-header {
        color: #ff6600;
        font-size: x-large;
    }
    padding: 4rem;
    min-height: 450px;
}

.yudu-subscribe-section {
    background-color: @primary-color;
    float: left;
    width: 100%;
    min-height: 40vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0 50px 0;

    .yudu-subscribe-body {
        position: relative;
        left: 30%;

        p {
            color: #000;
            font-weight: bold;
            padding: 2rem 0rem;
        }

        #yudu_subscribe, .yudu-subscribe-btn {
            width: 510px;
        }

        .yudu-subscribe-btn {
            background-color: #000;
            color: #fff;
            font-weight: bold;
        }
    }
}

.mobile-yudu-subscribe-section {
    background-color: @primary-color;
    float: left;
    width: 100%;
    min-height: 40vh;
    padding: 50px 0 50px 0;

    .yudu-subscribe-body {
        p {
            color: #000;
            font-weight: bold;
            padding: 2rem 0rem;
        }

        #yudu_subscribe, .yudu-subscribe-btn {
            width: 60%;
        }

        #yudu_subscribe, p {
            margin: 0px auto;
        }

        .yudu-subscribe-btn {
            background-color: #000;
            color: #fff;
            font-weight: bold;
        }
    }
}

.wider-section--share-and-follow {
    background-image: url('../img/Share_And_Follow_Section_bg.png');
    background-position: center bottom;
    overflow: hidden;
}

.yudu-join-section {
    background-image: url('../img/See _What_You_Missin_Callout_Banner.png');
    background-position: center bottom;
    overflow: hidden;

    h1 {
        color: #fff;
        font-size: 64px;
        font-weight: bold;
        text-shadow: 0px 0px 20px #000;
        padding-bottom: 4rem;
    }

    button {
        width: 35rem;
        height: 5rem;

        font-size: 32px;
    }
}

.mobile-yudu-join-section {
    background-image: url('../img/See _What_You_Missin_Callout_Banner.png');
    background-position: center bottom;
    overflow: hidden;
    float: left;
    width: 100%;
    min-height: 39vh;
    background-repeat: no-repeat;
    background-size: 162vw 40vh;
    background-position: center;
    // padding: 110px 0 120px 0;

    h1 {
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        text-shadow: 0px 0px 20px #000;
        padding-bottom: 4rem;
    }
}

.yudu-core-values-section {
    background-image: url('../img/Core_Values_bg.jpg');
    background-position: center bottom;
    // background-size: 100% 100vh !important;
    overflow: hidden;

    .main-header {
        border-bottom: 1px solid @primary-color;
        padding-bottom: 4rem;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
        color: @primary-color;
    }

    p {
        color: #fff;
        font-size: 18px;
    }
}

.yudu-wider-section {
    float: left;
    width: 100%;
    min-height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 110px 0 120px 0;
}

.the-yudu-feed-btn {
    position: absolute;
    bottom: 14rem;
    right: 0rem;
    width: 180px;
    box-shadow: 0px 0px 30px #000 !important;
}

 /**************************************************/
 /************  Why VUDU page styles ***************/
 /**************************************************/
 .why-vudu-hero {
    background-image: url('../img/Why_Yudu_Hero_bg.png');
    background-position: center bottom;
    overflow: hidden;

    h1 {
        text-shadow: 0px 0px 30px #000;
    }
 }

 .why-yudu-sign-up-bg {
    height: 100vh;

    &:before {
        background-image: url('../img/Yudu_Phone_Preview.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100vw;
        overflow: hidden;
        opacity: .14;
        position: absolute;
        top: 45px;
        width: 100%;
        height: 100%;
    }
 }

 .why-vudu-section-two {
     .main-header {
        // font-family: @Reklame;
        color: @primary-color;
     }
    
    // .why-vudu-body-text {
    //     margin-top: 25rem;
    // }

    .first-swoosh {
        width: 38vw;
        height: 60vh;
        position: relative;
        left: 17%;
        margin-top: -65px;
    }
    .second-swoosh {
        width: 38vw;
        height: 60vh;
        position: relative;
        left: 17%;
        margin-top: -65px;
        transform: scaleX(-1);
    }
 }

 /**************************************************/
 /************  Community page styles ***************/
 /**************************************************/
  .vudu-community-hero {
    background-image: url('../img/Community_Hero_bg.png');
    background-position: center bottom;
    overflow: hidden;

    h1 {
        text-shadow: 0px 0px 30px #000;
    }

    img {
        width: 100%;
    }

    b, small {
        text-align: left;
    }
 }

//  .yudu-community-body {
//      img {
//          width: 100% !important;
//          height: 300px;
//      }
//  }

 /****************************************************/
 /*************** Paywall style **********************/
 /****************************************************/
 .pay-wall-section-one-hero {
    background-color: @primary-color;
    min-height: 250px;
    margin-top: 21rem;
    padding: 2rem;

    .main-header {
        font-size: 64px;
    }
    .sub-header {
        font-weight: bold;
        color: #000;
    }
 }
 .pay-wall-section-one-body {
    background-image: url('../img/paywall_bg.jpg');

    .monthly-jumbotron, .yearly-jumbotron {
        min-height: 300px;
        box-shadow: 0px 0px 197px #222;
        text-align: center;
        background-color: rgba(255, 255, 255, .7);
        padding: 5rem;

        h1 {
            font-weight: bold;
            font-size: 64px;
            color: #000;
        }

        h2 {
            position: relative;
            top: -3rem;
        }

        .paywall-join-btn {
            position: relative;
            top: 8rem;
            left: 6rem;
            width: 300px;
            height: 50px;
            padding: .8rem;
            a {
                font-weight: bold;
                font-size: 28px;
                text-align: center;
                color: #FFF;
            }
        }
        .paywall-join-btn-month {
            background-color: @primary-color;
        }
        .paywall-join-btn-year {
            background-color: #000;
        }
    }

    .monthly-jumbotron {
        border-top: 15px solid @primary-color;
    }

    .yearly-jumbotron {
        border-top: 15px solid #000;
    }
 }

 .pay-wall-section-two-hero {
    background-color: @primary-color;
    min-height: 150px;
    margin-top: 21rem;
    padding: 3rem 0rem 2rem 0rem;

    .main-header {
        font-size: 64px;
    }
 }

 .pay-wall-section-two-body {
     h1 {
         font-size: 36px;
         color: @primary-color;
     }
    .signup-info-body {
        margin-top: 12rem;
    }

    .sign-up-info-btn {
        background-color: @primary-color;
        color: #FFF;
        font-weight: bold;
        width: 100%;
    }
 }


 // new comm images
 .community-images {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-top: 30px;

    li {
        margin: 20px;

        img {
            max-width: 300px;
            border-radius: 5px;
        }
    }
}

.com-title {
    color: #ff6600; 
    font-weight: bold; 
    font-size: 30px; 
    margin-top: 10px;
}

@media (min-width: 500px) {
    .com-title {
        margin-top: 70px;
    }
}

 /****************************************************/
 /************* Special cases Media queries **********/
 /****************************************************/
 @media(max-width: 768px) {
    .yudu-wider-section {
        float: left;
        width: 100%;
        min-height: 80vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 0px;
    }

    // .yudu-community-body {
    //     img {
    //         width: 100% !important;
    //         height: 124px;
    //     }
    // }

    .play-mission-video {
        width: 120px !important;
    }

    .why-vudu-body-text {
        padding: 2rem;
    }

    .timeline-user-avtar {
        width: 150px;
        height: 150px;
        top: 35px;
        left: 29%;

        img {
            width: 140px;
            height: 140px;
            position: absolute;
            top: 0px;
            border-radius: 50%;
            left: 0px;
        }

        .chang-user-avatar {
            top: -20%;
        }
    }

    .yudu-v2-group-avatar {
        position: absolute;
        top: 0rem;
        left: 6rem;

        img {
            width: 160px;
            height: 160px;
        }
    }

    .chang-user-avatar {
        position: absolute;
        left: 63%;
        top: 64%; 
    }
}

@media(max-width: 480px) {
    .home-banner__tcell {
        img {
            width: 125px !important;
        }

        .sub-header {
            font-size: large;
        }
    }
    
    .yudu-core-values-section {
        img {
            width: 45px;
            margin-top: 1.5rem;
        }
    }

    .mission-hero{
        .home-banner__tcell {
            position: relative;
            top: -3rem;
        }
    }

    .vudu-community-hero {
        height: 52vh;
        .home-banner__tcell {
            height: 49vh;
        }
    }

    .why-yudu-swoosh-container {
        .first-swoosh {
            width: 58vw;
            height: 36vh;
            position: relative;
            left: 8%;
        }
        .second-swoosh {
            width: 58vw;
            height: 36vh;
            position: relative;
            top: -15rem;
            left: 8%;
            transform: scaleX(-1);
        }
    }

    .why-yudu-second-section-third-body {
        position: relative;
        top: -60rem;
    }
}

@media(max-width: 992px) {
    .why-vudu-hero {
        background-size: 100vw 66vh;
        background-repeat: no-repeat;
        height: 350px;

        .home-banner__tcell {
            display: block;
            position: relative;
            // top: 9rem;
            width: 100%;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }

    .vudu-community-hero .home-banner__tcell {
        padding-top: 0;
    }

    .mission-hero {
        height: 80vh;

        .home-banner__slide {
            height: inherit;

            #missionVideo {
                min-width: 100%;
                max-height: 92%;
            }
        }

        .home-banner__tcell {
            display: table-cell;
            vertical-align: middle;
            height: auto;
            width: 100%;
            padding-top: 70px;
            padding-bottom: 50px;

            .play-mission-video {
                width: 85px !important;
            }
        }
    }

    .why-vudu-section-two {
        padding: 0rem !important;

        .why-yudu-swoosh-container {
            position: relative;
            top: -25rem;
        }

        .why-vudu-mobile-preview {
            background: url('../img/Yudu_Phone_Preview.png');
            background-position-y: 0%;
            background-repeat: repeat;
            background-size: 80vw;
            height: 135vh;
            background-position-y: 160px;
            background-repeat: no-repeat;
        }

        .why-vudu-mobile-preview-second {
            background: url('../img/Yudu_Phone_Preview.png');
            background-position-y: 0%;
            background-repeat: repeat;
            background-size: 80vw;
            height: 100vh;
            background-position-y: 160px;
            background-position-x: -150px;
            background-repeat: no-repeat;
        }

        .why-vudu-section-two-second-body {
            position: relative;
            top: -45rem;
        }

    }
}

/* ----------- 
css edits for responsiveness
----------- */
.why-join-btn {
    z-index: 2;
}

.why-yudu-sign-up-bg:before {
    background-image: none;
}

@media (max-width: 480px) {
    .mobile-yudu-join-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .why-vudu-body-text {
        .btn-primary {
            padding: 10px 50px;
        }
    }

    .why-vudu-body-text {
        margin-top: 75px !important;
    }

    .com-title {
        margin-top: 100px;
    }

    .mobile-yudu-exclusive {
        .section-para {
            margin-bottom: 30px;;
        }
    }
}

/* ----------- iPad Pro ----------- */
/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
      .home-banner__tcell {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
        height: auto !important;
      }

      .yudu-join-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .why-vudu-hero,
      .vudu-community-hero {
          min-height: 700px;

          .home-banner__trow {
              position: relative;
              top: 200px;
          }
      }
}

.modal-dialog {
    width: 60%;
}

.modal-content {
    padding-bottom: 0 !important;
}

// login / join button on hero instead of google / apple download
.login-join-btn {
    color: white;
    background-color: @primary-color;
    padding: 20px 50px;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    box-shadow: 0px 3px 5px black;
    margin: 10px;
    display: inline-block;

    &:hover {
        color: white;
    }
}

.subscribe-now-img {
    max-width: 100%;
}

/* ipad media query */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .why-yudu-phone {
        max-width: 200px;
    }

    .why-vudu-body-text {
        margin-top: 0 !important;
    }

    .first-row,
    .second-main-row,
    .third-main-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .first-row {
        margin-top: 75px;
    }

    .why-vudu-section-two .first-swoosh,
    .why-vudu-section-two .second-swoosh {
        width: 55vw !important;
    }

    .home-banner__tcell {
        height: 55vw !important;
    }

    .home-banner__trow {
        margin-bottom: 100px;
    }
}

/* ----------- iPhone X ----------- */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .why-yudu-phone {
        display: none;
    }

    .why-vudu-section-two .first-swoosh,
    .why-vudu-section-two .second-swoosh {
        display: none;
    }

    .second-main-row .why-yudu-text {
        margin-top: 0 !important;
    }
}

// mission video flash
#missionVideo {
    width: 100%;
    height: 100%;
}

#missionVideo[poster] {
    object-fit: cover;
}

.home-banner__slide {
    background: transparent !important;
}

.home-banner__tcell {
    padding-top: 200px !important;
    padding-bottom: 0 !important;
}

.navbar-default .navbar-nav > li > a {
    color: #444;
}

@media (max-width: 1010px) {
    .navbar-nav > li > a {
        color: white !important;
    }
}

// typeform bar
.navbar-center {
    float: none;
    display: inline-block;
    text-align: center;
    margin-left: 175px;
}

.navbar-link {
    color: white !important;
    padding: 10px;
    display: inline-block;

    &:hover {
        color: white;
    }
}
.typeform-bar {
    display: block;
    background: black;
    color: white;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    font-size: 14px;
    margin-bottom: 0;

    @media (max-width: 640px) {
        font-size: 12px;
        border-bottom: 1px solid #333;
    }
}

.why-vudu-body-text .sub-header {
    color: @primary-color;
    font-size: xx-large;
    margin-bottom: 15px;
}

.why-vudu-section-two .main-header {
    margin-bottom: 10px;
}

.the-yudu-feed .sub-header {
    margin-bottom: 25px;
}

.the-yudu-feed .main-header,
.yudu-exclusive-content .main-header,
.wider-section--share-and-follow .main-header {
    margin-bottom: 15px;
}

.wider-section--share-and-follow .sub-header {
    line-height: 1.3;
}

// header re-styling
.new-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #444;
        font-weight: bold;
        padding-left: 70px;
        padding-right: 70px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.navbar-btn-join {
    padding: 0 !important;
}

.section-para {
    margin-bottom: 20px;
}

.beta-feedback-btn {
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    font-weight: bold;

    &:hover {
        background-color: rgba(0,0,0,0.3);
        color: white;
    }

    @media (max-width: 640px) {
        background-color: @primary-color;
    }
}

// hiding chatbox on mobile
@media (max-width: 640px) {
    #chatBoxes {
        display: none;
    }

    // modal fix on mobile
    .modal-dialog {
        width: 100%;
        margin-left: 0;
    }
}